import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { Block, BlockAthenty, Button, Icon } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import Head from "../../../layout/head/Head";
import { Link } from "react-router-dom";
import LogoDark from "../../../images/logo-dark.png";

const Test = ({ number, disabled, ...props }) => {
  const [invoiceData, setInvoiceData] = useState("");
  const [invoiceDataText, setInvoiceDataText] = useState("");

  const [data] = useState(invoiceData);
  const [user, setUser] = useState();

  // let { invoiceId } = useParams();
  // console.log(invoiceId)
  useEffect(() => {
    getInvoiceSingle();
    // const id = invoiceId;
    // if (id !== undefined || null || "") {
    //   let spUser = data.find((item) => item.id === Number(id));
    //   setUser(spUser);
    // } else {
    //   setUser(data[0]);
    // }
  }, []);

  const getInvoiceSingle = async () => {
    await apiRequest(`/customer/a/invoice/22`, "GET").then((resp) => {
      // console.log(resp.data);
      const respSplit = resp.data.split("<split>").filter((n) => n);
      // console.log(respSplit);
      let result = {};
      // result.returnaddress = parser.parseFromString(respSplit[0].replace('data-',''), "application/xml").documentElement.textContent;
      const addressSplit = respSplit[0].replace("&nbsp;&nbsp;", ",").replace(/<BR>/g, ",").split(",");
      result.ourAddress1 = addressSplit[0]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.ourAddress2 = addressSplit[1].replace(/&nbsp;/g, " ").replace("\n", "");
      result.ourCity = addressSplit[2]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.ourCountry = addressSplit[3]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.ourZipcode = addressSplit[4]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");

      result.date = respSplit[1]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.invnum = respSplit[2]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.custnum = respSplit[3]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.payname = respSplit[4]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.company = respSplit[5]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.address1 = respSplit[6]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.address2 = respSplit[7]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.city = respSplit[8]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.state = respSplit[9]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.country = respSplit[10]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");
      result.zipcode = respSplit[11]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "");

      const incomeInvoiceRow = respSplit[12].split("<split-row>").filter((n) => n);
      let invoiceRows = [];
      for (let i in incomeInvoiceRow) {
        invoiceRows.push(
          incomeInvoiceRow[i]
            .replace(/&nbsp;/g, " ")
            .replace("\r\n", "")
            .replace("\n", "")
            .split("<split-col>")
            .filter((n) => n),
        );
      }
      result.invoiceRows = invoiceRows;
      const incomePayment = respSplit[13].split("<split-row>").filter((n) => n);
      const totalPaymenRow = [];
      for (let i in incomePayment) {
        const splited = incomePayment[i]
          .replace(/&nbsp;/g, " ")
          .replace("\r\n", "")
          .replace("\n", "")
          .split("<split-col>")
          .filter((n) => n);
        // console.log(splited);
        // totalPaymenRow.push(splited);
      }
      result.totalPaymenRow = totalPaymenRow;
      result.due = respSplit[14]
        .replace(/&nbsp;/g, " ")
        .replace("\r\n", "")
        .replace("\n", "")
        .split("<b>")
        .filter((n) => n);
      console.log(result);
      setInvoiceData(result);
      // result.totalPaymenRow = totalPaymenRow
      // console.log(result)
      // const incomeTotalSplited =  incomeTotalRow.split('<split-row>')
      // pTags.forEach((pTag) => {
      //   const className = pTag.getAttribute('class');
      //   const textContent = pTag.textContent.trim();
      //   dataObject[className] = textContent;
      // });
      // console.log(dataObject);

      // console.log(resp.data.invoice_text.split('\n'))
      // setInvoiceDataText(resp.data.invoice_text.split('\n'))
      // const invoiceText = resp.data.invoice_text.split('\n')
      // console.log(invoiceText)
      // console.log(invoiceText[2].split('   ')[14].split('  ')[1].split('#')[1])
      // const invoiceDetails ={
      //   date: invoiceText[2].split('   ')[14].split('  ')[0],
      //   invoice_number : invoiceText[2].split('   ')[14].split('  ')[1].split('#')[1],
      //   company_name : invoiceText[5],
      //   company_address1 : invoiceText[6],
      //   company_address2 : invoiceText[7],
      //   customer_name : invoiceText[11],
      //   customer_title : invoiceText[12],
      //   customer_address1 : invoiceText[13],
      //   customer_address2 : invoiceText[14],
      //   customer_address3 : invoiceText[15],
      // }
    });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Details"></Head>
      {
        <Content>
          <BlockAthenty
            head={"Smart IDV List"}
            nav="Admin / Billing / Invoice"
            title={`Invoice #${20}`}
            description="Your invoice details are given bellow."
            back="/customer/admin/billing/invoices"
            buttons={[
              {
                text: "Print",
                icon: "printer",
                link: "",
                onclick: "",
                tooltip: "Add Verification",
                id: "add-verification",
                form: "my-form",
                linkTo: "/customer/solutions/verify/add",
                color: "primary",
              },
            ]}
          />
          <Block>
            {/* <section
                className="not-found-controller"
                dangerouslySetInnerHTML={{ __html: invoiceData }}
            /> */}
            {/* {JSON.parse(invoiceData.data)} */}
            {invoiceData && (
              <div className="invoice">
                <div className="invoice-action">
                  <Link to={`${process.env.PUBLIC_URL}/print/invoice/${invoiceData.invnum}`} target="_blank">
                    <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                      <Icon name="printer-fill"></Icon>
                    </Button>
                  </Link>
                </div>
                <div className="invoice-wrap">
                  <div className="invoice-brand text-center">
                    <img src={LogoDark} alt="" />
                  </div>

                  <div className="invoice-head">
                    <div className="invoice-contact">
                      <span className="overline-title">Invoice To</span>
                      <div className="invoice-contact-info">
                        <h4 className="title">{invoiceData.company}</h4>
                        <ul className="list-plain">
                          <li>
                            <Icon name="map-pin-fill"></Icon>
                            <span>
                              {invoiceData.address2}, {invoiceData.address1}
                              <br />
                              {invoiceData.city}, {invoiceData.state} {invoiceData.zipcode}
                            </span>
                          </li>
                          {/* <li>
                          <Icon name="call-fill"></Icon>
                          <span>{user.phone}</span>
                        </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="invoice-desc">
                      <h3 className="title">Invoice</h3>
                      <ul className="list-plain">
                        <li className="invoice-id">
                          <span>Invoice ID</span>:<span>{invoiceData.invnum}</span>
                        </li>
                        <li className="invoice-date">
                          <span>Date</span>:<span>{invoiceData.date}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="invoice-bills">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {/* <th className="w-200">Description</th> */}
                            <th>Description</th>
                            <th className="text-center">Price</th>
                            <th className="text-center">Qty</th>
                            <th className="text-center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData.invoiceRows.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>{row[0]}</td>
                                <td className="text-center">{row[1]}</td>
                                <td className="text-center">{row[2]}</td>
                                <td className="text-center">{row[3]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <h6>Payments</h6>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {/* <th className="w-200">Description</th> */}
                            <th>Description</th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData.totalPaymenRow.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-start">{row[0]}</td>
                                <td></td>
                                <td></td>
                                <td className="text-center">{row[1]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="nk-notes ff-italic fs-12px text-soft">
                        Invoice was created on a computer and is valid without the signature and seal.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Block>
        </Content>
      }
    </React.Fragment>
  );
};

export default Test;
