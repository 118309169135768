import { Steps, Step } from "react-step-builder";
import { Icon, InputCheckbox, InputRadio, PreviewCard, UserAvatar } from "../../../../../../../components/Component";
import { Button, Card, CardBody, CardFooter, Collapse, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../../../../utils/Api";
import { toast } from "react-toastify";
import { findUpper } from "../../../../../../../utils/Utils";



const KycWizardFinal = (props) => {
    const [loading, setLoading] = useState()
    const [details, setDetails] = useState()
    const [isOpen, setIsOpen] = useState();
    const [userDetail, setUserDetails] = useState()
    const [filteredData, setFilterData] = useState([])

    const { watch, unregister, handleSubmit, register, errors, setValue, getValues, submit, setError } = props

    // const filterData = (obj) => {
    //     const result = {};

    //     for (const [key, value] of Object.entries(obj)) {
    //         // Check for children with value true
    //         const trueChildren = Object.entries(value).filter(([_, v]) => v.value === true);

    //         // If there are any true children, add them to the result
    //         if (trueChildren.length > 0) {
    //             result[key] = Object.fromEntries(trueChildren);
    //         }
    //     }
    //     setFilterData(result)
    // };


    useEffect(() => {
        // filterData(getValues('params'))
    }, [])


    return (
        <>
            <div className="accordion-item pb-2">
                Are you certain you want to submit a verification request based on the information provided below?
            </div>
            <Row>
                <Col xl="6" sm="6" xs="12" className="mb-2">

                    <Row>


                        <>
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Client Information

                                </label>
                            </div>
                            <Card style={{ width: '93%' }} className="card-preview mx-auto" >
                                <div className="team">
                                    <div className="user-card user-card-s2">
                                        <UserAvatar className="md" text={findUpper(getValues('client_name_first') + ' ' + getValues('client_name_last'))} >
                                            <div className="status dot dot-lg dot-success"></div>
                                        </UserAvatar>
                                        <div className="user-info">
                                            <h6>{getValues('client_name_first') + ' ' + getValues('client_name_last')}</h6>
                                            <span className="sub-text">{getValues('client_email')}</span>
                                        </div>
                                    </div>
                                    <div className="team-details">
                                        <p>Phone Number: {getValues('client_phone')}</p>
                                    </div>
                                    <div className="team-details">
                                        <p>Client ID: {getValues('client_id')}</p>
                                    </div>
                                </div >
                            </Card>

                        </>


                    </Row>

                </Col>
                <Col xl="6" sm="6" xs="12" className="mb-2">

                    <Row>


                        <>
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Matter Information

                                </label>
                            </div>
                            {/* <Card style={{ height: '200px', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" > */}
                            <Card style={{ width: '93%', height: '207px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="card-preview mx-auto" >
                                <div className="team">

                                    <div className="team-details">
                                        <p>Matter ID: {getValues('matter')}</p>
                                    </div>
                                    <div className="team-details">
                                        <p>Matter Reason: {getValues('request_reason')}</p>
                                    </div>

                                </div >
                            </Card>

                        </>


                    </Row>

                </Col>

            </Row >


            <div className="actions clearfix">
                <ul>
                    <li>
                        <Button color="primary" onClick={props.formSubmit}>Send</Button>
                    </li>
                    <li>
                        <Button color="primary" onClick={props.prev}>Back</Button>
                    </li>
                </ul>
            </div>
        </>


    );
}

export default KycWizardFinal