import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import * as Sentry from '@sentry/browser';
import * as Sentry from "@sentry/react";
import App from "./App";

import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
// Sentry.init({dsn: "https://9b6e141fad724e3ca23b743dfa1eeae2@sentry.ghostsyntax.com/10"});

if (process.env.NODE_ENV !== 'development') {

  Sentry.init({
    dsn: "https://4d8c5c00e2622ba387d410146fcf7792@sentry.sipstack.com/18",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.3 : 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/secure\.athenty\.com\/api/, /^https:\/\/api\.athenty\.com/],
    replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.3 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
      if (process.env.NODE_ENV === "production" && event.level !== "error") {
        return null;
      }
      return event;
    },
    environment: process.env.NODE_ENV,
  });
}

// console.log(process.env)

if (process.env.NODE_ENV === 'production') {
  console.log = function () { }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
);
