import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import SelectSingle from "./SelectSingle";

const CountrySelection = ({
    id,
    selectedCountry,
    handleCountryChange,
    label,
    name,
    onChange,
    requiredMessage,
    required,
    className,
    register = function () { },
    setValue,
    errors,
    placeholder,
    onselect,
    data
}) => {
    var handleBlank = false;
    const options = useMemo(() => countryList().getData(), []);
    return (
        <div className="pt-1">
            <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                    {data.label}
                    {data.require && <span className="text-danger"> *</span>}
                </label>
            </div>
            <div className="form-control-wrap">
                <SelectSingle
                    requiredMessage={requiredMessage}
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    options={options}
                    id={data.id}
                    className={className}
                    placeholder={placeholder}
                    data={data}
                />
            </div>
        </div>
    );
};

export default CountrySelection;
