import { Steps, Step } from "react-step-builder";
import { InputRadio, PreviewCard } from "../../../../../../../components/Component";
import { Button, Card, CardBody, CardFooter, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";



const ShareWizardStart = (props) => {
    const setValue = () => {

    }

    const submitForm = async (data) => {
        await props.submit()
        props.next();
    };

    return (
        <form className="content clearfix" onSubmit={props.handleSubmit(submitForm)}>
            <div className="form-group">
                <div className="form-control-wrap">
                    <h4>User Type</h4>
                    <p>
                        Please choose user type
                    </p>
                    <InputRadio
                        errors={props.errors}
                        setValue={setValue}
                        label="New User"
                        id="existing"
                        selectedValue={props.watch("user")}
                        value
                        ="new"
                        name="user"
                        register={props.register}
                        requiredMessage="This field is required"
                        className="form-control-lg p-0 border-0 form-control"
                    ></InputRadio>
                    <InputRadio
                        setValue={setValue}
                        label="Existing User"
                        id="new"
                        selectedValue={props.watch("user")}
                        value="existing"
                        name="user"
                        register={props.register}
                        requiredMessage="This field is required"
                        className="form-control-lg p-0 border-0 form-control"
                    ></InputRadio>

                    <div className="actions clearfix">
                        <ul>
                            <li>
                                <Button color="primary" type='submit' >next</Button>
                            </li>

                        </ul>
                    </div>


                </div>
            </div>
        </form>
    );
}

export default ShareWizardStart