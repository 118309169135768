import React, { useContext, useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import { apiRequest } from "../../../../utils/Api";
import { Block, PreviewCard, ClientListTable, BlockAthenty, Loading } from "../../../../components/Component";
import { shareListColumns } from "../../../components/table/TableData";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import MainTable from "../../../components/athenty/table/Main.Table";
import { UserContext } from "../../../../layout/Index";


const Share = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { userData } = useContext(UserContext)



  const col = shareListColumns.filter(function (col) {
    if (col.access) {
      if (col.access.includes(userData.class)) return col
      else return null
    } else {
      return col
    }
  })

  useEffect(() => {
    // console.log(location.state)
    if (location.state === "clientAdded") {
      toast.success("The request has been sent", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (location.state === "requestDelet") {
      toast.success("The request has been canceled", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    // getVerifytList();
  }, []);

  // const getVerifytList = async () => {
  //   setLoading(true);
  //   try {
  //     await apiRequest("/customer/a/solution/smart-share?sort=ASC", "GET").then((resp) => {
  //       if (resp.code === 200) {
  //         console.log(resp.data);
  //         setTableData(resp.data);
  //         setLoading(false);
  //       } else {
  //         toast.error(resp.message, {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //         });
  //         setLoading(false);
  //       }
  //     });
  //   } catch (error) {
  //     toast.error(error, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //     setLoading(false);
  //   }
  // };

  const tools = [
    {
      text: "Approve",
      icon: "check",
    },
    {
      text: "Reject",
      icon: "cross",
    },
    {
      text: "Cancel",
      icon: "trash",
    },
  ];

  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content page="component">
          <BlockAthenty
            head={"Smart Share List"}
            nav="Solutions"
            title={"Smart Share Requests"}
            description="Your smart share requests."
            buttons={[
              {
                text: "Request",
                icon: "plus",
                link: "",
                onclick: "",
                tooltip: "Request",
                id: "add-verification",
                form: "my-form",
                linkTo: "/customer/solutions/share/add",
                className: "btn-icon ",
                color: "primary",
              },
            ]}
          />

          <Block>
            <MainTable compact columns={col} route="/customer/a/solution/smart-share" />
            {/* <MainTable compact tools={tools} columns={verifyListColumns} route="/customer/a/solution/smart-share" /> */}
          </Block>
          {/* <Block size="lg">
            <PreviewCard>
              {tableData && (
                <ClientListTable
                  notFoundMessage="No verifications found"
                  data={tableData}
                  columns={verifyListColumns}
                  pagination
                  className="nk-tb-list"
                  selectableRows
                />
              )}
            </PreviewCard>
          </Block> */}
        </Content>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};
export default Share;
