import Content from "../../../../../layout/content/Content";
import React, { useEffect, useRef, useState } from "react";
import KycAddNew from "./kycAddNew/KycAddNew";
import KycAddExisting from "./kycAddExisting/KycAddExisting";

import { Block, BlockAthenty, Icon } from "../../../../../components/Component";
import { Card } from "reactstrap";
import KycWizard from "./kycWizard/kycWizard";

const KycAdd = () => {
  const submitRef = useRef();
  const [selectedTab, setSelectedTab] = useState("new");

  const asignRef = () => {
    return submitRef.current.click();
  };

  return (
    <React.Fragment>
      <Content page="component">
        <BlockAthenty
          head={"New Smart KYC Request"}
          nav="Solutions / Smart KYC"
          title={"New Smart KYC Request"}
          back={"/customer/solutions/verify"}
          description="Send new KYC request"
          buttons={[
            {
              // type:"submit",
              text: "Send",
              icon: "send",
              link: "",
              onclick: asignRef,
              tooltip: "Send verification request",
              id: "submit",
              form: "verify-form",
              color: "primary",
            },
          ]}
        />
      </Content>
      <Block className="nk-content-wrap">
        <Card className="card-bordered ">
          <div className="card-inner card-inner-lg">
            <KycWizard />
          </div>
        </Card>
      </Block>
    </React.Fragment>
    // <React.Fragment>
    //   <Content page="component">
    //     <BlockAthenty
    //       head={"New Smart KYC Request"}
    //       nav="Solutions / Smart KYC"
    //       title={"New Smart KYC Request"}
    //       back={"/customer/solutions/kyc"}
    //       description="Send new verification request"
    //       buttons={[
    //         {
    //           // type:"submit",
    //           text: "Send",
    //           icon: "send",
    //           link: "",
    //           onclick: asignRef,
    //           tooltip: "Send verification request",
    //           id: "submit",
    //           form: "kyc-form",
    //           color: "primary",
    //         },
    //       ]}
    //     />
    //   </Content>
    //   <Block className="nk-content-wrap">
    //     <Card className="card-bordered ">
    //       <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
    //         <li
    //           onClick={() => setSelectedTab("new")}
    //           className={`nav-item ${selectedTab === `new` ? "active current-page" : ""} `}
    //         >
    //           <div role="button" className={`nav-link ${selectedTab === "new" ? "active" : ""}`}>
    //             <Icon name="user-add"></Icon>
    //             <span>Add Client</span>
    //           </div>
    //         </li>
    //         {/* <li
    //           onClick={() => setSelectedTab("existing")}
    //           className={`nav-item ${selectedTab === "existing" ? "active" : ""}`}
    //         >
    //           <div role="button" className={`nav-link ${selectedTab === "existing" ? "active" : ""}`}>
    //             <Icon name="user-check"></Icon>
    //             <span>Existing Client</span>
    //           </div>
    //         </li> */}
    //       </ul>

    //       <div className="card-inner card-inner-lg">
    //         {selectedTab === "new" && <KycAddNew submitRef={submitRef} />}
    //         {/* {selectedTab === "existing" && <VerifyAddExisting submitRef={submitRef} />} */}
    //       </div>
    //     </Card>
    //   </Block>
    // </React.Fragment>
  );
};
export default KycAdd;
