import { Steps, Step } from "react-step-builder";
import { Icon, InputCheckbox, InputRadio, PreviewCard } from "../../../../../../../components/Component";
import { Button, Card, CardBody, CardFooter, Collapse, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../../../../utils/Api";
import { toast } from "react-toastify";



const ShareWizardFinal = (props) => {
    const [loading, setLoading] = useState()
    const [details, setDetails] = useState()
    const [isOpen, setIsOpen] = useState();
    const [userDetail, setUserDetails] = useState()
    const [filteredData, setFilterData] = useState([])

    const { watch, unregister, handleSubmit, register, errors, setValue, getValues, submit, setError } = props

    const filterData = (obj) => {
        const result = {};

        for (const [key, value] of Object.entries(obj)) {
            // Check for children with value true
            const trueChildren = Object.entries(value).filter(([_, v]) => v.value === true);

            // If there are any true children, add them to the result
            if (trueChildren.length > 0) {
                result[key] = Object.fromEntries(trueChildren);
            }
        }
        setFilterData(result)
    };


    useEffect(() => {
        filterData(getValues('params'))
    }, [])


    return (
        <>
            <div className="accordion-item pb-2">
                Are you certain you want to submit a verification request based on the information provided below?
            </div>
            <Row>
                <Col>
                    <Card className="card-bordered p-2">
                        <h6>
                            User information
                        </h6>
                        <b>
                            First name: {getValues('client_name_first')}
                        </b>
                        <b>
                            Last name: {getValues('client_name_last')}
                        </b>
                        <b>
                            Client ID: {getValues('client_id')}
                        </b>
                        <b>
                            Client Phone: {getValues('client_phone')}
                        </b>
                        <b>
                            Client email: {getValues('client_email')}
                        </b>
                    </Card>
                </Col>
                <Col>
                    <Card className="card-bordered p-2">
                        <h6>
                            Selected Document
                        </h6>

                        {Object.entries(filteredData).map(([category, items]) => {

                            return (
                                <div className="pb-2">
                                    {/* <h6 className="title">{category.replace('_', ' ')}</h6> */}
                                    <ul className="list-unstyled">

                                        <>
                                            {Object.entries(items).map(([key, item]) => {
                                                console.log(item)
                                                return (
                                                    <li key={key}>
                                                        <div className="list-unstyled ">
                                                            <b>{item.text} {item.text === 'Other' && ': ' + getValues('other')}</b>
                                                        </div>
                                                    </li>
                                                )

                                            })}
                                        </>

                                    </ul>
                                </div>
                            )
                        })}
                    </Card>
                </Col>
            </Row>

            <div className="actions clearfix">
                <ul>
                    <li>
                        <Button color="primary" onClick={props.formSubmit}>submit</Button>
                    </li>
                    <li>
                        <Button color="primary" onClick={props.prev}>back</Button>
                    </li>
                </ul>
            </div>
        </>


    );
}

export default ShareWizardFinal