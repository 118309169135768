const Disclaimer = ({ data }) => {
    console.log('disclaimer')
    return (
        <ul className="list list-sm list-checked">
            <li>{data.text}</li>
        </ul>
    )

}

export default Disclaimer