import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { Block, BlockAthenty, Button, Icon, Input, Loading, Textarea } from "../../../../../components/Component";
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import VerifyActivities from "./shareActivities/ShareActivities";
import VerifyVerification from "./shareVerification/ShareVerification";
import VerifyBiometrics from "./shareBiometrics/ShareBiometrics";
import VerifyDocuments from "./shareDocuments/ShareDocuments";
import VerifytNote from "./shareNote/ShareNote";
import VerifyProfile from "./shareProfile/ShareProfile";
import VerifyThirdParties from "./shareThirdParties/ShareThirdParties";
import VerifyBusiness from "./shareBusiness/ShareBusiness";
import VerifyMatter from "./shareMatter/ShareMatter";
import VerifyCompliance from "./shareCompliance/ShareCompliance";
import { ClientDetails } from "../../../../data/clientDetails";
import { toast, ToastContainer } from "react-toastify";
import { apiRequest } from "../../../../../utils/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import VerifyHistory from "./shareHistory/ShareHistory";

const ShareDetail = () => {
  const [loading, setLoading] = useState();
  const [selectedTab, setSelectedTab] = useState("Matter");
  const [data, setData] = useState();
  const [historyData, setHistoryData] = useState();
  const [modal, setModal] = useState(false);
  const [cancelModal, setcancelModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const location = useLocation();
  const [clickedButtonId, setClickedButtonId] = useState(null);
  const [dLoading, setDLoading] = useState();
  const steps = [
    "initiated",
    "welcome",
    "document_1",
    "document_2",
    "person",
    "location",
    "contact",
    "biometric_face",
    "biometric_voice",
    "estate",
    "business",
  ];

  const params = useParams();
  const request_id = params.id;

  const navigate = useNavigate();


  const onSubmitShareForm = async () => {
    await apiRequest(`/customer/a/solution/smart-share/${request_id}/share`, "post", getValues2()).then((resp) => {
      if (resp.code === 200) {
        toggleShareModal();
        navigate(`/customer/solutions/verify/${request_id}`);
        toast.success("Share request sent.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log("shared");
      } else {
        toast.error("Something went wrong. Please contact support.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
    setSelectedTab("Matter");
  };

  useEffect(() => {
    getRequestDetails();
    // getHistoryDetail();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleShareSubmit,
    formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm();

  const toggleModal = () => {
    console.log("toggle");
    setModal(!modal);
    reset();
  };

  const toggleCancelModal = () => {
    setcancelModal(!cancelModal);
  };

  const toggleShareModal = () => {
    console.log("toggle");
    setShareModal(!shareModal);
    reset2();
  };

  const cancelRequest = async () => {
    await apiRequest(`/customer/a/solution/smart-share/${params.id}/cancel`, "PUT").then((resp) => {
      if (resp.code === 200) {
        navigate("/customer/solutions/verify", { state: "requestDelet" });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const getRequestDetails = async (decisionrResp = null) => {
    setLoading(true);
    try {
      await apiRequest(`/customer/a/solution/smart-share/${params.id}`, "GET").then((resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp.data);
          setData(resp.data);
          setLoading(false);
          if (decisionrResp) {
            toast.success(decisionrResp, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  };

  const getHistoryDetail = async () => {
    try {
      setLoading(true);
      await apiRequest(`/customer/a/solution/smart-share/${params.id}/history`, "GET").then(async (resp) => {
        console.log(resp);
        if (resp.code === 200) {
          console.log(resp.data);
          setHistoryData(resp.data);
          setLoading(false);
        } else {
          toast.error(resp.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      });
    } catch (error) {
      // setLoading(false)
      console.log(error);
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };



  return (
    <React.Fragment>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <BlockAthenty
            head={"Smart IDV"}
            back
            nav="Solutions / Smart IDV"
            title={data && data.name}
            description={`Matter: ${data && data.matter_id} / ${data && data.matter_reason}`}
            buttons={[
              // data &&
              //   data.status === "approved" &&
              // {
              //   text: "Share Report",
              //   icon: "share",
              //   link: "",
              //   onclick: toggleShareModal,
              //   tooltip:
              //     data && data.status !== "complete" && data.active
              //       ? "Unable to share report until request reviewed"
              //       : data && !data.active
              //         ? "The request has been canceled"
              //         : "Share Report",
              //   id: "sharePdf",
              //   form: "my-form",
              //   color: "primary",
              //   hidden: data && data.status === "canceled" ? true : false,
              //   disabled: data && data.status !== "complete",
              // },
              // {
              //   text: "Download Report",
              //   icon: "download",
              //   link: "",
              //   // onclick: { toggleModal },
              //   tooltip:
              //     data && data.status !== "complete" && data.active
              //       ? "Unable to download report until request reviewed"
              //       : data && !data.active
              //         ? "The request has been canceled"
              //         : "Download Report",
              //   id: "downloadPdf",
              //   form: "my-form",
              //   // linkTo: `/download-pdf/${params.id}`,
              //   onclick: getPdf,
              //   color: "primary",
              //   newTab: true,
              //   hidden: data && data.status === "canceled" ? true : false,
              //   disabled: data && data.status !== "complete",
              //   // disabled: "disabled",
              // },
            ].filter(Boolean)}
            extra={[
              {
                type: "badge",
                title: "Status",
                value:
                  data && data.active
                    ? (data.status.charAt(0).toUpperCase() + data.status.slice(1)).replace("_", " ")
                    : data && !data.active
                      ? "Canceled : " + (data.status.charAt(0).toUpperCase() + data.status.slice(1)).replace("_", " ")
                      : "",
                className: "",
                outline: "warning",
                textColor: "success",
                size: "sm",
              },

              {
                type: "text",
                title: "Completion Date",
                value:
                  data && data.submit_date
                    ? moment.utc(data.submit_date).local().format("YYYY-MM-DD hh:mm")
                    : "Incomplete",
              },
              // {
              //   type: "text",
              //   title: "Review Date",
              //   value:
              //     data && data.review_date
              //       ? moment.utc(data.review_date).local().format("YYYY-MM-DD hh:mm")
              //       : "Not reviewed",
              // },
            ]}
            extraButtons={
              // extraButtons
              [
                // {
                //   text: "Resend",
                //   icon: "redo",
                //   link: "",
                //   onclick: resendSmartVerify,
                //   tooltip:
                //     data && data.status !== "initiated"
                //       ? "Can not resend after verification started"
                //       : "Resend Smart IDV Request",
                //   id: "resend-request",
                //   form: "my-form",
                //   linkTo: ``,
                //   color: "gray",
                //   // hidden: data && data.status !== "initiated" ? true : false,
                //   disabled: data && data.status !== "initiated" ? true : false,
                //   outline: false,
                // },
                // {
                //   text: "Review",
                //   icon: "eye-alt",
                //   link: "",
                //   onclick: toggleModal,
                //   tooltip:
                //     data && steps.includes(data.status) && data.active
                //       ? "Wait for form submission to review request"
                //       : data && data.status === "complete"
                //         ? "Already reviewed"
                //         : data && !data.active
                //           ? "The request has been canceled"
                //           : "Review",
                //   id: "review-request",
                //   form: "my-form",
                //   linkTo: ``,
                //   color: "warning",
                //   hidden: data && data.status === "canceled" ? true : false,
                //   disabled: (data && steps.includes(data.status)) || (data && data.status === "complete"),
                //   outline: false,
                // },
                // {
                //   text: "Cancel",
                //   icon: "cross",
                //   link: "",
                //   onclick: toggleCancelModal,
                //   tooltip:
                //     data && !steps.includes(data.status) && data.active
                //       ? "Cannot be cancelled once completed by client."
                //       : data && !data.active
                //         ? "The request has been canceled"
                //         : "Cancel Smart IDV Request",
                //   id: "cancel-request",
                //   form: "my-form",
                //   linkTo: ``,
                //   color: "gray",
                //   hidden: data && data.status === "canceled" ? true : false,
                //   disabled: data && (!steps.includes(data.status) || !data.active),
                //   outline: false,
                // },

              ]
            }
          />
          <Block>
            <Card className="card-bordered">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li
                  onClick={() => setSelectedTab("Matter")}
                  className={`nav-item pe-4 ${selectedTab === `Matter` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Matter" ? "active" : ""}`}>
                    <Icon name="list"></Icon>
                    <span>Matter</span>
                  </div>
                </li>
                {/* <li
                  onClick={() => setSelectedTab("Profile")}
                  className={`nav-item pe-4 ${selectedTab === `Profile` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Profile" ? "active" : ""}`}>
                    <Icon name="user-circle"></Icon>
                    <span>KYC</span>
                  </div>
                </li> */}
                {/* <li
                  onClick={() => setSelectedTab("Third-Parties")}
                  className={`nav-item pe-4 } ${selectedTab === `Third-Parties` ? "active current-page" : ""} `}
                >
                  <div role="button" className={` nav-link ${selectedTab === "Third-Parties" ? "active" : ""}`}>
                    <Icon name="users" color="danger"></Icon>
                    <span>Third-Parties</span>
                  </div>
                </li> */}
                {/* <li
                  onClick={() => setSelectedTab("Business")}
                  className={`nav-item pe-4 } ${selectedTab === `Business` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Business" ? "active" : ""}`}>
                    <Icon name="briefcase"></Icon>
                    <span>Business</span>
                  </div>
                </li> */}
                <li
                  onClick={() => setSelectedTab("Documents")}
                  className={`nav-item pe-4 ${selectedTab === `Documents` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Documents" ? "active" : ""}`}>
                    <Icon name="cc-secure"></Icon>
                    <span>Documents</span>
                  </div>
                </li>
                {/* <li
                onClick={()=>setSelectedTab('Verifications')}
                className={`nav-item pe-4 ${
                  selectedTab === `Verifications` ? "active current-page" : ""} `}
              >
                <div
                  role="button"
                  className={`nav-link ${selectedTab === 'Verifications' ? "active" : ""}`}
                  
                >
                  <Icon name="check-circle-cut"></Icon>
                  <span>Verifications</span>
                </div>
              </li> */}
                {/* <li
                  onClick={() => setSelectedTab("Biometrics")}
                  className={`nav-item pe-4 ${selectedTab === `Biometrics` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Biometrics" ? "active" : ""}`}>
                    <Icon name="eye-alt"></Icon>
                    <span>Biometrics</span>
                  </div>
                </li> */}
                {/* <li
                  onClick={() => setSelectedTab("Compliance")}
                  className={`nav-item pe-4 ${selectedTab === `Compliance` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "Compliance" ? "active" : ""}`}>
                    <Icon name="list-check"></Icon>
                    <span>Compliance</span>
                  </div>
                </li> */}
                {/* <li
                  onClick={() => {
                    setSelectedTab("History");
                    getHistoryDetail();
                  }}
                  className={`nav-item pe-4 ${selectedTab === `History` ? "active current-page" : ""} `}
                >
                  <div role="button" className={`nav-link ${selectedTab === "History" ? "active" : ""}`}>
                    <Icon name="history"></Icon>
                    <span>History</span>
                  </div>
                </li> */}
              </ul>

              <div className="card-inner ">
                {selectedTab === "Matter" && <VerifyMatter data={data} />}
                {selectedTab === "Profile" && <VerifyProfile more={data} />}
                {selectedTab === "Verifications" && <VerifyVerification data={data} />}
                {selectedTab === "Biometrics" && <VerifyBiometrics data={data} />}
                {selectedTab === "Documents" && <VerifyDocuments data={data} />}
                {selectedTab === "Activities" && <VerifyActivities data={ClientDetails.activities} />}
                {selectedTab === "Notes" && <VerifytNote data={ClientDetails.notes} />}
                {selectedTab === "Third-Parties" && <VerifyThirdParties data={data} />}
                {selectedTab === "Business" && <VerifyBusiness data={data} />}
                {selectedTab === "Compliance" && <VerifyCompliance data={data} />}
                {selectedTab === "History" && <VerifyHistory data={historyData} />}
              </div>
            </Card>
          </Block>
          <ToastContainer />

        </Content>
      )}
    </React.Fragment>
  );
};

export default ShareDetail;
