import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import HeaderCCR from "../../../layout/header/HeaderCCR";
import AuthFooter from "../../auth/AuthFooter";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/ccr-forms/Header";
import { Block, Loading } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../utils/Api";
import { Col, Row } from "reactstrap";
import Disclaimer from "../../components/ccr-forms/Disclaimer";
import CountrySelection from "../../components/ccr-forms/CountrySeletion";
import RegionSelection from "../../components/ccr-forms/RegionSelection";
import DocScan from "../../components/ccr-forms/DocScan";


const SmartShare = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [locationPermission, setLocationPermission] = useState();
  const [location, setLocation] = useState();
  const [permissionErrors, setPermissionErros] = useState([]);
  const [errorModal, setErrorModal] = useState();
  const [data, setData] = useState()

  const [formData, setFormData] = useState({
    // step: 'welcome',
    request_id: searchParams.get("request_id"),
    tos: false,
    news: false,
    adult: false,
  });

  const componentMap = {
    header: Header,
    disclaimer: Disclaimer,
    country: CountrySelection,
    region: RegionSelection,
    camera: DocScan,
  }

  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch
  } = useForm();

  useEffect(() => {
    checkStep();
    // window.scrollTo(0, 0, 'smooth');
    // getUserInfo();
  }, []);

  const checkStep = async () => {
    setLoading(true)
    try {
      await apiRequest(`/ccr/share/check/${request_id}`).then((resp) => {
        if (resp.code === 200) {
          console.log(resp.data)
          setData(resp.data)
          console.log(data)
          setLoading(false)
        }
      });
    } catch (error) {
      console.log(error)
    }
  };

  const onFormSubmit = async (e) => {
    if (!locationPermission) {
      setPermissionErros((prevItems) => [...prevItems, "location"]);
      setErrorModal(true);
      console.log(errorModal);
      console.log(permissionErrors);
      return;
    }
    setSubmitButtonLoading(true);
    setValue("tos", true);
    setValue("news", true);
    await locationAccess();
    setValue("adult", true);
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    console.log(getValues());

    try {
      apiRequest("/ccr/verify/step/0", "POST", getValues()).then((resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.message);
          // console.log(localStorage.getItem("at_request"));
          // localStorage.setItem("at_position", JSON.stringify({ step: 1 }));
          navigate("/ccr/verify/form?request_id=" + request_id);
          // localStorage.setItem("token", fetchResponse.message);
          // navigate('/customer')
          // setLoading(false);
        } else {
          // console.log(resp.message);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
    }
  };

  const onSelect = async () => {

  }


  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          // setValue("location", { latitude, longitude });
          setLocationPermission(true);
          setPermissionErros((prevItems) => prevItems.filter((item) => item !== "location"));
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
        },
        options,
      );
    });
  };


  return (
    <React.Fragment>
      <Head title="Verification Request" />
      <HeaderCCR title='Smart Share'></HeaderCCR>
      {loading && <Loading />}
      {!loading && data && (
        <Content page="component">
          <Block size="lg">
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix p-4" >
              <Block>
                <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
                  <div className="gy-4 row">
                    <div className="entry">
                      <div className="input-group input-group-lg">
                        <Row>
                          {data.form.map((item, index) => {
                            const Component = componentMap[item.type]
                            console.log(Component)
                            if (Component) {
                              return (
                                <Col className="col-12 col-md-12 col-sm-12 col-xl-6 col-lg-6">
                                  <Component watch={watch} errors={errors} register={register} onselect={onSelect} setValue={setValue} key={index} data={item} />
                                </Col>
                              )
                            }

                          })}

                        </Row>
                      </div>
                    </div>
                  </div>
                </form>
              </Block>
            </div>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default SmartShare;
