import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Block } from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import Documents from "./Documents";
import Location from "./Location";
import Contact from "./Contact";
import FaceScan from "./FaceScan";
import Attestation from "./Attestation";
import Complete from "./Complete";
import { apiRequest } from "../../../utils/Api";
import { useCookies } from "react-cookie";

const Header = (props) => {
  return (
    <div
      className="steps clearfix"
      style={{
        position: "fixed",
        left: "5%",
        right: "5%",
        top: "0px",
        width: "90%",
        overflow: "hidden",
        zIndex: "99",
        background: "white",
      }}
    >
      <ul className="pt-2" style={{ right: "10px" }}>
        <li className={props.current >= 1 ? "first done" : "first"}>
          <a href="#step-01">
            <span className="number center">01</span>
            {/* <h5 className="d-none d-xl-block center">Document <span style={{display:'inline-block'}}>1 of 2</span></h5> */}
          </a>
        </li>
        <li className={props.current >= 2 ? "done" : ""}>
          <a href="#step-02">
            <span className="number center">02</span>
            {/* <h5 className="d-none d-xl-block center">Document <span style={{display:'inline-block'}}>2 of 2</span></h5> */}
          </a>
        </li>
        <li className={props.current >= 3 ? "done" : ""}>
          <a href="#step-03">
            <span className="number center">03</span>
            {/* <h5 className="d-none d-xl-block center">Personal</h5> */}
          </a>
        </li>
        <li className={props.current >= 4 ? "done" : ""}>
          <a href="#step-04">
            <span className="number center">04</span>
            {/* <h5 className="d-none d-xl-block center">Location</h5> */}
          </a>
        </li>
        <li className={props.current >= 5 ? "done" : ""}>
          <a href="#step-05">
            <span className="number center">05</span>
            {/* <h5 className="d-none d-xl-block center">Contact</h5> */}
          </a>
        </li>
        <li className={props.current >= 6 ? "done" : ""}>
          <a href="#step-06">
            <span className="number center">06</span>
            {/* <h5 className="d-none d-xl-block center">Face Scan</h5> */}
          </a>
        </li>
        <li className={props.current >= 7 ? "done" : ""}>
          <a href="#step-07">
            <span className="number center">07</span>
            {/* <h5 className="d-none d-xl-block center">Voice Recognition</h5> */}
          </a>
        </li>



      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

const VerifyForm = (...props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["at_request"]);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [number1, setNumber1] = useState(1);
  const [selected1, setSelected1] = useState("");
  const [number2, setNumber2] = useState(2);
  const [selected2, setSelected2] = useState("");
  const [requestData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState()
  const [currentStep, setCurrentStep] = useState()
  // console.log(location.search)

  useEffect(() => {
    // console.log(cookies.at_request)
    // Disable horizontal scrolling
    document.body.style.overflowX = "hidden";

    checkStep();
    // Ensure the body takes the full viewport height
    document.body.style.height = "100vh";
    document.body.style.width = "100%";
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    // const step = JSON.parse(localStorage.getItem('at_position')).step
    return () => {
      // Re-enable scrolling when the component unmounts
      document.body.style.overflowX = "auto";
      document.body.style.height = "";
      document.body.style.width = "";
      document.body.style.margin = "";
      document.body.style.padding = "";
    };
  }, []);
  const Progress = (prop) => {
    // console.log('progfress')
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const checkStep = async () => {
    console.log(request_id);
    console.log(queryParameters);
    await apiRequest(`/ccr/verify/step/check/${request_id}`).then((resp) => {
      console.log(resp);
      if (resp.code === 401) navigate("/errors/401-classic", { state: { message: resp.message } });
      if (resp.code === 200) {
        console.log(200)
        setCurrentStep(resp.data.step)
        if (resp.data.step === 8) {
          navigate(`/ccr/verify/decision?request_id=${request_id}`);
        }
        // setCookie("at_request", searchParams.get("request_id"));
      }
    });
  };

  const getRequestData = () => {
    return requestData;
  };
  return (
    <React.Fragment>
      <Head title="Smart IDV" />
      <Block className="pt-4 nk-auth-body wide-sm wide-md wide-lg wide-xl" style={{ overflowX: "hidden" }}>
        {/* <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img
              className="logo-light logo-img logo-img-lg"
              src={Logo}
              alt="logo"
            />
            <img
              className="logo-dark logo-img logo-img-lg"
              src={LogoDark}
              alt="logo-dark"
            />
          </Link>
        </div> */}
        <div>
          <Content page="component">
            <Block size="lg">
              {/* <PreviewCard> */}
              <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix" style={{ paddingTop: "25px" }}>
                <Steps state={requestData} config={config} current={currentStep}>
                  <Step component={Contact} scroll={Progress} state={requestData} request_id={request_id} />
                  <Step component={Location} scroll={Progress} request_id={request_id} data={data} />
                  <Step
                    component={Documents}
                    doc={number1}
                    setDoc={setNumber1}
                    selected={selected1}
                    scroll={Progress}
                    setSelected={setSelected2}
                    checkStep={checkStep}
                    request_id={request_id}
                  />
                  <Step
                    component={Documents}
                    doc={number2}
                    setDoc={setNumber2}
                    selected={selected2}
                    scroll={Progress}
                    setSelected={setSelected1}
                    request_id={request_id}
                  />
                  <Step component={FaceScan} scroll={Progress} requestData={getRequestData} request_id={request_id} />
                  <Step component={Attestation} scroll={Progress} state={getRequestData} request_id={request_id} />
                  <Step component={Complete} scroll={Progress} state={getRequestData} request_id={request_id} />

                </Steps>
              </div>
              {/* </PreviewCard> */}
            </Block>
          </Content>
        </div>
      </Block>
    </React.Fragment>
  );
};

export default VerifyForm;
