//url for production
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode"; // You may need to install this library
import React from "react";

export async function isAuthenticated() {
  const cookie = await Cookies.get("token");
  // Add logic to verify if the cookie is valid and not expired
  if (cookie) {
    return true;
  } else {
    return false;
  }
}

export async function request(
  req,
  res,
  uri = "",
  method = "GET",
  _data = false,
  dataType = "application/json",
  async = true,
) {
  // dataTypes: text/plain text/html ...
  // set options -----------------------
  var options = {
    method: method,
    // body: JSON.stringify(_data),
    headers: { "Content-Type": dataType },
  };
  if (_data) options.body = JSON.stringify(_data);
  if (uri.includes("/a/")) {
    options.headers.cookie = `token=${req.cookies.token}`;
  }
  // using module: node-fetch --------------
  var resp = await fetch(uri, options);

  var data = "";
  if (dataType === "application/json" && async) {
    data = await resp.json();
  } else {
    data = await resp.text();
  }

  // console.log(data);
  return data;
}

export var url = "";
if (process.env.mode === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];
  if (string === "RT_System") {
    return "ST";
  }
  if (string.split(" ").length < 2) {
    return string[0].toUpperCase() + string[1].toUpperCase();
  }
  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  // console.log(extractedString)
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

export const findFirst = (string) => {
  const splitedString = string.split(" ");

  let finalString = [];
  for (var i = 0; i < splitedString.length; i++) {
    const UpperString = splitedString[i][0].toUpperCase();
    finalString.push(UpperString);
  }
  // console.log(finalString)
  return finalString.join(" ");
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

export const dateConverter = (incomeDate, split) => {
  const date = new Date(incomeDate);
  date.setSeconds(0, 0);
  if (split) {
    return date
      .toLocaleString([], { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
      .split(", ");
  } else {
    return date.toLocaleString();
  }
};

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export async function checkJWTExpiration() {
  const token = Cookies.get("token"); // 'jwt' is the name of your cookie
  if (token) {
    // Retrieve the expiration date of the token
    var expirationDate = new Date(Cookies.get("token_expiration"));

    // Get the current date
    var currentDate = new Date();

    // Compare the expiration date with the current date
    if (expirationDate < currentDate) {
      // Token has expired
      Cookies.remove("token", {
        path: "/",
        domain: ".athenty.com",
      });
      Cookies.remove("token", {
        path: "/",
        domain: "localhost",
      });
      Cookies.remove("token");
      return false;
    } else {
      // Token is still valid
      return true;
    }
  } else {
    // Token doesn't exist
    return false;
  }

  // try {
  //   const decodedToken = jwtDecode(token);
  //   const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

  //   if (decodedToken.exp && decodedToken.exp < currentTime) {
  //     // Token has expired
  //     return false;
  //     // console.log('false2')
  //   } else {
  //     // Token is still valid
  //     return true;
  //   }
  // } catch (error) {
  //   // Token is invalid or couldn't be decoded
  //   return false;
  // }
}

export function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

export function capitalizeFirstLetter(str) {
  return str.replace(str[0], str[0].toUpperCase());
}

export function getInitials(fullName) {
  // Split the full name into an array of words
  const words = fullName.trim().split(/\s+/);

  // Extract the first letter of each word and join them
  const initials = words.map(word => word.charAt(0).toUpperCase()).join('');

  return initials;
}

export function convertToTitleCase(input) {
  console.log(input)
  if (!input) return null
  return input
    .toLowerCase() // Convert the entire string to lowercase
    .split(' ') // Split the string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a single string
}


export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
