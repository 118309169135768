import React from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'


const SelectSingle = ({ ...props }) => {
    console.log(props)

    return (
        <div>
            {props.label && (
                <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                        {props.label}
                        {props.requiredMessage && <span className="text-danger">*</span>}
                    </label>
                </div>
            )}
            <div className="form-control-select mb-2">
                {props.createable ? <CreatableSelect
                    {...props.register(props.name, { required: props.requiredMessage })}
                    className={`react-select-container  ${props.className ? props.className : ""}`}
                    classNamePrefix="react-select"
                    defaultValue={props.options[props.defaultValue]}
                    style={{ height: "38px", minHeight: "35px" }}
                    onChange={(e, name) => {
                        if (Array.isArray(e)) {
                            const data = [];
                            for (let i = 0; i < e.length; i++) {
                                data.push(e[i].value);
                            }
                            props.setValue(props.data.id, data);
                        } else {
                            props.setValue(props.data.id, e.value);
                        }
                    }}
                    onselect={props.onselect}
                    {...props}
                /> :
                    <Select
                        {...props.register(props.data.id, { required: props.requiredMessage })}
                        className={`react-select-container  ${props.className ? props.className : ""}`}
                        classNamePrefix="react-select"
                        defaultValue={props.options[props.defaultValue]}
                        style={{ height: "38px", minHeight: "35px" }}
                        onChange={(e, name) => {
                            if (Array.isArray(e)) {
                                const data = [];
                                for (let i = 0; i < e.length; i++) {
                                    data.push(e[i].value);
                                }
                                props.setValue(props.data.id, data);

                            } else {
                                props.setValue(props.data.id, e.value);
                            }
                        }}
                        isSearchable={props.isSearchable || false}
                        onMenuScrollToBottom={true}
                        {...props}
                    />}
                {props.errors && props.errors[props.data.id] && <p className="invalid">{props.errors[props.data.id].message}</p>}
            </div>
        </div>
    );
};

export default SelectSingle;
