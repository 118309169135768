import React, { useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import { ClientListTable } from "../../../../../components/Component";
import moment from "moment";
import { apiRequest } from "../../../../../utils/Api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { capitalizeFirstLetter } from "../../../../../utils/Utils";

const HistoryTab = ({ data }) => {
  console.log(data);
  const dataTableColumns = [
    {
      name: "Event",
      selector: (row) => row.event,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>
            {capitalizeFirstLetter(row.event)} - {row.event_type}
          </span>
        </div>
      ),
      sortable: false,
      grow: 1.5,
    },
    {
      name: "User",
      selector: (row) => row.name,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>{row.name || 'Client'}</span>
          {/* <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>{row.email}</span> */}
        </div>
      ),
      sortable: false,
    },
    // {
    //   name: "IP / useragent",
    //   selector: (row) => row.user,
    //   style: { padding: "5px 10px" },
    //   cell: (row) => (
    //     <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
    //       <span style={{ paddingLeft: "5px" }}>{row.ipv4}</span>
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      name: "Date",
      selector: (row) => row.created_at,
      style: { padding: "5px 10px" },
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
          <span style={{ paddingLeft: "5px" }}>{moment(row.created_at).format("MM/DD/YYYY")}</span>
          <span style={{ paddingLeft: "5px", paddingTop: "5px", fontSize: "12px" }}>
            {moment(row.created_at).fromNow()}
          </span>
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <Content>
        <ClientListTable columns={dataTableColumns} data={data} search={false} show={false} />
      </Content>
    </React.Fragment>
  );
};

export default HistoryTab;
