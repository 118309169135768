import Jon from "../../../images/jon.png";
import Cookies from "js-cookie";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import { Block, Icon, Loading } from "../../../components/Component";
import React, { useState } from "react";
import { Alert, Col, Row } from "reactstrap";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../utils/Api";
import { Link } from "react-router-dom";

const Complete = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const url = window.location.hostname;
  // console.log(url)
  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    console.log(Cookies.get());
    Cookies.remove("at_request");
    // Cookies.remove("at_request", { path: "/", domain: ".athenty.com" });
    // Cookies.remove("at_request", { path: "/ccr", domain: ".athenty.com" });
    // Cookies.remove("at_request", { path: "/ccr/verify", domain: ".athenty.com" });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    setLoading(true);
    await apiRequest("/ccr/kyc/" + request_id, "GET").then((resp) => {
      if (resp.code === 200) {
        console.log(resp);
        setData(resp.data.request);
        // setValue("from_name", resp.data.request.from_name);
        // setValue("from_title", resp.data.request.from_title);
        // setValue("from_company", resp.data.request.from_company);
        // setValue("matter", resp.data.request.matter);
        // setValue("reason", resp.data.request.reason);
        setLoading(false);
      }
      // console.log(getValues());
    });
  };
  return (
    <React.Fragment>
      <Head title="Complete" />
      {/* <HeaderCCR></HeaderCCR> */}
      <div className="mb-2"></div>
      {loading && <Loading />}
      {!loading && (
        <Content>
          <div className=" content-page wide-md m-auto">
            <Block>
              <div className="brand-logo text-center">
                {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img
                  className="logo-light logo-img logo-img-lg"
                  src={Logo}
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img logo-img-lg"
                  src={LogoDark}
                  alt="logo-dark"
                />
              </Link> */}
              </div>

              <div className="kyc-app wide-sm m-auto">
                <div className="pt-3 nk-block-head nk-block-head-lg wide-xs mx-auto">
                  <div className="nk-block-head-content text-start">
                    <h2 className="nk-block-title fw-normal">
                      <i className="mt-1 ni ni-check-circle text-primary "></i>
                      &nbsp;In Review!
                    </h2>
                    <h3 className="nk-block-title fw-normal">
                      Thank you for using Athenty and for your help in the fight against fraud.
                    </h3>
                    <div className="nk-block-des pt-4 text-start">
                      {/* <p className="fs-6"> */}
                      <p style={{ fontSize: "0.93rem" }}>You have submitted your Athenty ID verification to:</p>

                      <div className="project-head text-start">
                        <a
                          href="#title"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="project-title"
                        >
                          {/* <div
                            id="at_avatar"
                            className="user-avatar"
                            style={{
                              width: "60px",
                              height: "60px",
                              display: "block",
                            }}
                          >
                            <img id="at-avatar-top-name" src={Jon} alt="" />
                          </div> */}
                          <div className="project-info">
                            <h6 className="title">{data.from_name}</h6>
                            <span className="sub-text"> {data.from_title}</span>
                            <span className="sub-text"> {data.from_company}</span>
                          </div>
                        </a>
                      </div>
                      <div className="project-details text-start">
                        <div>
                          Matter ID:{" "}
                          <span style={{ fontSize: "13px" }} className="text-muted">
                            {data.matter_id}
                          </span>
                        </div>

                        <div>
                          Matter Reason:{" "}
                          <span style={{ fontSize: "13px" }} className="text-muted">
                            {data.matter_reason}
                          </span>
                        </div>
                      </div>

                      {/* <div className="team">
                        <div className="user-card user-card-s2">
                          <div
                            id="at_avatar"
                            className="user-avatar"
                            style={{
                              width: "60px",
                              height: "60px",
                              display: "block",
                            }}
                          >
                            <img id="at-avatar-top-name" src={Jon} alt="" />
                          </div>
                          <div className="user-info">
                            <h6>{request.from_name}</h6>
                            <span className="sub-text">
                              {request.from_title}
                            </span>
                            <span className="sub-text">
                              {request.from_company}
                            </span>
                          </div>
                        </div>
                        
                        
                          </div> */}
                      {/* <div className="row justify-content-center">
                      <div
                        className="user-card"
                        style={{ maxWidth: "fit-content" }}
                      >
                        <div
                          id="at_avatar"
                          className="user-avatar"
                          style={{
                            width: "60px",
                            height: "60px",
                            display: "block",
                          }}
                        >
                          <img id="at-avatar-top-name" src={Jon} alt="" />
                        </div>
                        <div
                          id="at-check"
                          className="user-avatar"
                          style={{
                            backgroundColor: "rgb(29, 185, 84)",
                            display: "none",
                            width: "50px",
                            height: "50px",
                          }}
                        >
                          <span>
                            <i
                              className="mt-1 ni ni-check-thick"
                              style={{
                                marginTop: "10px",
                                fontSize: "20px",
                                color: "white",
                              }}
                            ></i>
                          </span>
                        </div>
                        <br />
                        <div className="ms-2 text-start">
                          <strong id="req_name">Jonathan Geller</strong>
                          <a
                            className="ps-1"
                            id="email"
                            href="mailto://tochange@tochange.com"
                            target="_blank"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            rel="noreferrer"
                          >
                            <em className="icon ni ni-mail"></em>
                          </a>
                          <br />
                          <i id="req_title">CEO</i>
                          <br />
                          <strong>
                            <span id="company_name">Sipstack</span>
                            <a
                              className="ps-1"
                              id="website"
                              href="https://tochange.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <em className="icon ni ni-external-alt"></em>
                            </a>
                          </strong>
                        </div>
                      </div>
                    </div> */}
                      {/* <strong>Matter ID: {request.matter}</strong>
                    <br></br>
                    <i className="fs-6">{data.id}</i>
                    <br></br>
                    <strong>Reason:</strong>
                    <br></br> */}
                      {/* <i className="fs-6">{data.reason}</i> */}
                      {/* <div className="text-center pt-4">
                          <p>If you have any question, please contact our support team <a href="mailto:support@athenty.com">support@athenty.com</a></p>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Block>
          </div>
          {/* <div className="mx-auto">
            <Alert className="alert-icon mt-5" color="primary">
              <Icon name="alert-circle" />
              Coming Soon: Interested in keeping track of how your ID verification is used or accessed? Want to be able
              to stop access once your matter is completed? Did you know that Athenty can also protect your mobile from
              fraudulent phone calls? <a href="/#">Request an invite</a>.
            </Alert>
          </div> */}
          <div className="mx-auto">
            <Alert className="alert-icon mt-5" color="primary">
              <Icon name="alert-circle" />
              {/* New Feature: Interested in keeping track of how your ID verification is used or accessed? Want to be able
              to stop access once your matter is completed? Did you know that Athenty can also protect your mobile from
              fraudulent phone calls? <a href="/#">Request an invite</a>. */}
              <div className="mb-1">
                New Feature: You can now complete and track your Athenty requests on your mobile device with the Athenty Protect mobile app.
              </div>
              <Row className="mb-2 mt-1">
                <Col><Link className="" target="_blank" to='https://apps.apple.com/ca/app/athenty-protect/id6502211430'><img width={'80%'} src='/images/appStores/apple.png' alt='apple store'></img></Link></Col>
              </Row>
              <Row>
                <Col><Link target="_blank" to='https://play.google.com/store/apps/details?id=com.athenty.protect&pcampaignid=web_share'><img width={'80%'} src='/images/appStores/google.png' alt='apple store'></img></Link></Col>
              </Row>
            </Alert>
          </div>
        </Content>
      )}
      {/* <Footer>
                        <div style={{position:'fixed', bottom:'0px'}}><AuthFooter /></div>
                </Footer> */}
    </React.Fragment>
  );
};

export default Complete;
