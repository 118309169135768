import { Steps, Step } from "react-step-builder";
import { Input, InputRadio, Loading, PreviewCard, UserAvatar } from "../../../../../../../../components/Component";
import { Button, Card, CardBody, CardFooter, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RSelect2 from "../../../../../../../../components/select/ReactSelect2";
import { apiRequest } from "../../../../../../../../utils/Api";
import { findUpper } from "../../../../../../../../utils/Utils";



const KycWizardUserExisting = (props) => {
    // console.log(props)
    const [options, setOptions] = useState()
    const [loading, setLoading] = useState(true)
    const [clientList, setClientList] = useState()
    const [clientDetails, setClieantDetails] = useState()
    const [isNewMatter, setIsNewMatter] = useState()
    const { watch, unregister, handleSubmit, clearErrors, register, errors, setValue, getValues, submit, setError } = props.props

    useEffect(() => {
        getClientList()
        console.log(getValues())
    }, [])

    const onselect = (name, value) => {
        if (name === "client") {
            console.log(options)
            const result = clientList.find(obj => obj.uuid === value)
            setValue('client_name_first', result.name_first)
            setValue('client_name_last', result.name_last)
            setValue('client_id', result.external_id)
            setValue('client_email', result.email)
            setValue('client_phone', result.phone)

            // setValue("client", isNaN(value) ? value : options.filter(obj => obj.value === value)[0].label);
        }
        if (name === "matter") {
            setValue("matter", isNaN(value) ? value : props.matterList.filter(obj => obj.value === value)[0].label);
            setValue("request_reason", isNaN(value) ? value : props.matterList.filter(obj => obj.value === value)[0].reason);
            const isExisting = props.matterList.some(option => option.label === getValues('matter'));
            if (!isExisting) {
                setIsNewMatter(true)
                setValue('request_reason', null)
            }
            else setIsNewMatter(false)
        }
        if (getValues(name)) {
            delete errors[name];
        }
    };


    // useEffect(() => {
    //     console.log(options)
    // }, [options])

    const getClientList = async () => {
        await apiRequest(`/customer/a/client`, "get").then(res => {
            let optionsList = []
            setClientList(res.data)
            // setValue('clientList', res.data)
            console.log(res.data)
            for (let client of res.data) {
                console.log(client)
                optionsList.push({
                    value: client.uuid, label: client.name + ' - ' + client.external_id
                })
            }
            setOptions(optionsList)
            console.log(optionsList)
            setLoading(false)
        })
    }
    return (
        <>
            {loading ? <Loading />
                :
                <div className="form-control-wrap">
                    <h4>Client</h4>
                    <p>
                        Please Select Client
                    </p>

                    <Container>
                        <Row>

                            <Col xl="6" sm="6" xs="12" className="mb-2">

                                <Row>
                                    <RSelect2
                                        onselect={onselect}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        // selectedCountry={watch("region")}
                                        label="Client/Client ID"
                                        register={register}
                                        requiredMessage="This field is required"
                                        id="client"
                                        name="client"
                                        errors={errors}
                                        options={options}
                                        placeholder="Type client name to search"
                                        className="form-control-lg p-0 border-0 form-control"
                                        isSearchable={true}
                                    />
                                </Row>
                                <Row>
                                    {clientList.filter(obj => obj.uuid === props.props.watch('client')).map(client => {
                                        return (

                                            <>
                                                <div className="form-label-group">
                                                    <label className="form-label" htmlFor="default-01">
                                                        Client Information

                                                    </label>
                                                </div>
                                                <Card style={{ width: '93%' }} className="card-preview mx-auto" >
                                                    <div className="team">
                                                        <div className="user-card user-card-s2">
                                                            <UserAvatar theme={client.avatarBg} className="md" text={findUpper(client.name)} image={client.image}>
                                                                <div className="status dot dot-lg dot-success"></div>
                                                            </UserAvatar>
                                                            <div className="user-info">
                                                                <h6>{client.name}</h6>
                                                                <span className="sub-text">{client.email}</span>
                                                            </div>
                                                        </div>
                                                        <div className="team-details">
                                                            <p>Phone Number: {client.phone}</p>
                                                        </div>
                                                        <div className="team-details">
                                                            <p>Client ID: {client.external_id}</p>
                                                        </div>
                                                    </div >
                                                </Card>
                                            </>
                                        )
                                    })}
                                </Row>

                            </Col>


                            <Col xl="6" sm="6" xs="12" className="mb-2">
                                <Row>
                                    <RSelect2
                                        onselect={onselect}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        // selectedCountry={watch("region")}
                                        label="Matter"
                                        register={register}
                                        requiredMessage="This field is required"
                                        id="matter"
                                        name="matter"
                                        errors={errors}
                                        createable={true}
                                        options={props.matterList}
                                        placeholder="Select or type new matter"
                                        className="form-control-lg p-0 border-0 form-control"
                                    />
                                </Row>
                                {isNewMatter && <Row>

                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="default-01">
                                            Matter Description or Reason<span className="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <textarea
                                            id="matter_reason"
                                            name="matter_reason"
                                            type="text"
                                            className="  form-control form-control-lg "
                                            {...register("request_reason", {
                                                required: "This field is required",
                                                pattern: {
                                                    value: /^.{1,128}$/,
                                                    message: "Max 128 characters",
                                                },
                                            })}
                                        ></textarea>
                                        {errors.request_reason && <p className="invalid">{errors.request_reason.message}</p>}
                                    </div>

                                </Row>}



                                {/* {clientList.filter(obj => obj.uuid === props.props.watch('client')).map(client => {
                                        return (
                                            <Card className="card-bordered p-2">
                                                <div>Client Name: {client.name}</div>
                                                <div>Client Email: {client.email}</div>
                                                <div>Client Phone: {client.phone}</div>
                                                <div>Client ID: {client.external_id}</div>

                                            </Card>
                                        )
                                    })} */}
                                {/* {options.filter(obj => obj.value === props.props.watch('client')))} */}

                            </Col>


                        </Row>

                    </Container >
                    <div className="actions clearfix">
                        <ul>
                            <li>
                                <Button color="primary" type='submit' >Next</Button>
                            </li>
                            <li>
                                <Button color="primary" onClick={props.props.prev} >Back</Button>
                            </li>
                        </ul>
                    </div>
                </div >
            }
        </>

    );
}

export default KycWizardUserExisting