import { Steps, Step } from "react-step-builder";
import { InputRadio, InputCheckbox, Loading, PreviewCard, Input } from "../../../../../../../components/Component";
import { Button, Card, Collapse, CardBody, CardFooter, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../../../../utils/Api";
import { toast } from "react-toastify";



const ShareWizardType = (props) => {
    const { watch, unregister, handleSubmit, register, errors, setValue, getValues, submit, setError } = props;
    const [loading, setLoading] = useState()
    const [details, setDetails] = useState()
    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        if (getValues('params')) {
            setDetails(getValues('params'))
        } else {
            getShareDetailList()
        }
        console.log(getValues('params'))
    }, [])

    useEffect(() => {
        props.setValue('params', details)
    }, [details])

    useEffect(() => {
        props.watch('verificationType') === 'complete' && props.unregister('params')
    }, [props.watch('verificationType')])

    const getShareDetailList = async () => {
        await apiRequest("/customer/a/solution/smart-share/data-list", "GET",).then((resp) => {
            setLoading(true)

            if (resp.code === 200) {
                // setValue('detail', resp.data)
                for (const category in resp.data) {
                    for (const item in resp.data[category]) {
                        const currentItem = resp.data[category][item];
                        currentItem.value = false; // Add value property
                    }
                }
                console.log(resp.data)
                setDetails(resp.data)
                setLoading(false)
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }

    const submitForm = async (data) => {
        console.log(getValues())

        const check = await checkValues(getValues('params'))
        console.log(check)
        if (!check) {
            setError('params', 'Select one')
            console.log(errors)
            return
        }


        await props.submit()
        props.next();
    };

    const handleChange = async (e, category) => {
        const { name, checked } = e.target;

        // Create a new state object with the updated value
        await setDetails(prevDetails => ({
            ...prevDetails,
            [category]: {
                ...prevDetails[category],
                [name]: {
                    ...prevDetails[category][name],
                    value: checked
                }
            }
        }));


        console.log(details); // This will log the previous state due to the asynchronous nature of setState
    }

    const checkValues = (data) => {
        // Flatten the object and extract the values
        const values = Object.values(data).flatMap(category =>
            Object.values(category).map(item => item.value)
        );

        // Check if at least one value is true
        const hasTrueValue = values.some(value => value === true);

        if (hasTrueValue) {
            return true
            // Proceed to the next step
            console.log("Proceeding to the next step...");
        } else {
            return false
            // Return error
            console.error("Error: All values are false. Please provide at least one valid document.");
        }
    };

    return (
        <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
            {loading ?
                <Loading />
                :
                <div className="form-group">
                    <div className="form-control-wrap">
                        <h4>Seelction</h4>
                        <p>
                            Please Select the document
                        </p>
                        {/* <InputRadio
                            errors={props.errors}
                            setValue={setValue}
                            label="Smart IDV"
                            id="complete"
                            selectedValue={props.watch("verificationType")}
                            value="complete"
                            name="verificationType"
                            register={props.register}
                            requiredMessage="This field is required"
                            className="form-control-lg p-0 border-0 form-control"
                        ></InputRadio>
                        <InputRadio
                            setValue={setValue}
                            label="Smart Share"
                            id="customized"
                            selectedValue={props.watch("verificationType")}
                            value="customize"
                            name="verificationType"
                            register={props.register}
                            requiredMessage="This field is required"
                            className="form-control-lg p-0 border-0 form-control"
                        ></InputRadio> */}
                        {details && Object.entries(details).map(([category, items], index) => {

                            return (
                                <ul className="list-unstyled">
                                    {Object.entries(items).map(([key, item]) => {
                                        return (
                                            <li key={key}>
                                                <div className="list-unstyled ">

                                                    {key === 'other' && item.value ?
                                                        <Row>
                                                            <Col>
                                                                <InputCheckbox checked={item.value} onChange={(e) => handleChange(e, category)} className="list-unstyled ms-5" label={item.text} id={key} />
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    errors={props.errors}
                                                                    required
                                                                    requiredMessage={{
                                                                        required: "This field is required",
                                                                        pattern: {
                                                                            value: /^.{1,20}$/,
                                                                            message: "Max 20 characters",
                                                                        },
                                                                    }}
                                                                    register={props.register}
                                                                    type="text"
                                                                    id={key}
                                                                    name="other"
                                                                    placeholder='Name of the document'
                                                                    className="form-control-lg form-control">
                                                                </Input>

                                                            </Col>
                                                        </Row>
                                                        :
                                                        <InputCheckbox checked={item.value} onChange={(e) => handleChange(e, category)} className="list-unstyled ms-5" label={item.text} id={key} />
                                                    }
                                                </div>
                                            </li>
                                        )

                                    })}
                                </ul>)
                        })}


                        {errors && errors.params && <p className="invalid">{errors.params.message}</p>}
                        {/* {details && Object.entries(details).map(([category, items], index) => {
                            return (
                                <div className="accordion-item">
                                    <div
                                        className="accordion-head"
                                        onClick={() => isOpen === index ? setIsOpen() : setIsOpen(index)}
                                    >
                                        <h6 className="title">{category.replace('_', ' ')}</h6>
                                        <span className="accordion-icon"></span>
                                    </div>
                                    <Collapse
                                        className="accordion-body"
                                        isOpen={isOpen === index ? true : false}
                             
                                    >
                                        <div className="accordion-inner">
                                            <ul className="list-unstyled">
                                                {Object.entries(items).map(([key, item]) => {
                                                    return (
                                                        <li key={key}>
                                                            <div className="list-unstyled ">
                                                                <InputCheckbox checked={item.value} onChange={(e) => handleChange(e, category)} className="list-unstyled ms-5" label={item.text} id={key} />
                                                            </div>
                                                        </li>
                                                    )

                                                })}
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                            )
                        })} */}
                    </div>
                    <div className="actions clearfix">
                        <ul>
                            <li>
                                <Button color="primary" type='submit' >next</Button>
                            </li>
                            <li>
                                <Button color="primary" onClick={props.prev}>Back</Button>
                            </li>
                        </ul>
                    </div>


                </div>

            }
        </form >


    );
}

export default ShareWizardType