import { Steps, Step } from "react-step-builder";
import { Input, InputRadio, Loading, PreviewCard } from "../../../../../../../components/Component";
import { Button, Card, CardBody, CardFooter, Container } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RSelect2 from "../../../../../../../components/select/ReactSelect2";
import { apiRequest } from "../../../../../../../utils/Api";
import { toast } from "react-toastify";
import ShareWizardUserNew from "./ShareWizardUserNew/ShareWizardUserNew";
import ShareWizardUserExisting from "./ShareWizardUserExisting/ShareWizardUserExisting";



const ShareWizardUser = (props) => {
    const { watch, unregister, handleSubmit, register, errors, setValue, getValues, submit, setError } = props; // Destructure from props
    const [matterList, setMatterList] = useState();
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        unregister('client')
        unregister('client_email')
        unregister('client_id')
        unregister('client_name_first')
        unregister('client_name_last')
        unregister('client_phone')
        unregister('matter')
        unregister('request_reason')
        unregister('params')
        getMatterListShort()
    }, [])

    const getMatterListShort = async () => {
        await apiRequest("/customer/a//matter/matter_short", "GET",).then((resp) => {
            setLoading(true)

            if (resp.code === 200) {
                // navigate("/customer/solutions/verify", { state: "clientAdded" });
                setMatterList(resp.data)
                setLoading(false)
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    };

    const onFormSubmit = () => {

        const clientValue = getValues('user')
        console.log(errors)
        if (clientValue === 'existing') {
            if (!getValues('client')) {
                setError('client', {
                    type: 'manual',
                    message: 'This field is required',
                })
                return
            }
        }
        submit()
        props.next()
    }

    return (
        <>
            {loading ? <Loading />
                :
                < form className="content clearfix" onSubmit={handleSubmit(onFormSubmit)}>
                    {getValues('user') === 'new' ? (
                        <div className="form-group">
                            <ShareWizardUserNew matterList={matterList} props={props} />
                        </div>)
                        :
                        (<div className="form-group">
                            <ShareWizardUserExisting matterList={matterList} props={props} />
                        </div>)
                    }
                </form >

            }
        </>

    );
}

export default ShareWizardUser