import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Block, Button, Icon, Input, Loading } from "../../../components/Component";
import { apiRequest } from "../../../utils/Api";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const Contact = ({ ...props }) => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  const [loading, setLoading] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    if (modalOpen) {
      setSmsLoading(false);
    }
    setModalOpen(false);
  };
  const [load, setLoad] = useState(0);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  if (load < 1) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoad(2);
  }

  useEffect(() => {
    checkStep();
    getUserInfo();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const checkStep = async () => {
    await apiRequest(`/ccr/kyc/step/check/${props.request_id}`).then((resp) => {
      console.log(resp);
      if (resp.code === 401) {
        navigate("/errors/401-classic", { state: { message: resp.message } });
      } else if (resp.code === 200) {
        if (resp.data.data && resp.data.data.to_email) setValue('email', resp.data.data.to_email)
        // console.log(resp.data.step);
        if (resp.data.step > 2) {
          // console.log("step >2");
          props.jump(resp.data.step);
        } else if (resp.data.step === 0) {
          // console.log("step 0");
          navigate("/ccr/verify?request_id=" + request_id);
        } else {
        }
      }

      setLoading(false);
    });
  };

  const getUserInfo = async () => {
    await apiRequest("/ccr/kyc/" + request_id, "GET").then((resp) => {
      if (resp.code === 200) {
        // console.log(resp);
        setValue("email", resp.data.request.to_email);
      }
      // console.log(resp);
    });
  };

  const sendSms = async (e) => {
    console.log(e.key)
    if (e.key === 'Enter') return
    setLoading(true);
    await apiRequest("/ccr/kyc/phone", "POST", getValues()).then((resp) => {
      console.log(resp);
      if (resp.code === 200) {
        console.log(getValues('email'))
        setLoading(false);
        openModal();
      } else {
        setSmsLoading(false);
        console.log("error response: ", resp.message);
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setValue("location", { latitude, longitude });
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
          resolve({ latitude: null, longitude: null });
        },
        options,
      );
    });
  };

  const onFormSubmit = async () => {
    console.log("submit");
    setSmsLoading(true);
    const location = await locationAccess();
    console.log(getValues());
    try {
      await apiRequest("/ccr/kyc/phone/verify", "POST", getValues()).then((resp) => {
        console.log(resp);
        if (resp.code === 200) {
          setSuccess(true);
          console.log(resp);
          toast.success(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setTimeout(() => {
            submit();
          }, 2000);
        } else {
          setSmsLoading(false);
          toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async () => {
    console.log("submit");
    const location = await locationAccess();
    const data = {
      email: getValues("email"),
      verify_email: true,
      phone: getValues("phone"),
      verify_phone: true,
      latitude: location.latitude,
      longitude: location.longitude
    };

    await apiRequest("/ccr/kyc/step/5", "POST", data).then((resp) => {
      if (resp.code === 200) {
        // console.log(resp);
        setTimeout(() => {
          props.jump(resp.data.step);
        }, 2000);
      } else {
        setSmsLoading(false);
        console.log("error response: ", resp.message);
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Contact" />
      <div className="mb-2"></div>

      <Content>
        <div className="content-page wide-md m-auto">
          <Block>
            <h5>Contact Information</h5>
            <p>Please enter your contact information.</p>

            <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
              <div className="gy-4 row">
                <div className="entry">
                  <Row>
                    <Col className="col-12 col-md-12 col-sm-12 col-xl-6 col-lg-6">
                      <div className="form-control-wrap">
                        <span className="nk-kycfm-label-text">
                          <b>Email Address</b>
                          <span className="text-danger">&nbsp;*</span>
                        </span>
                        <div className="input-group input-group-lg">
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            value={watch("email")}
                            disabled
                            placeholder="email@email.com"
                          />

                          <Button
                            disabled
                            style={{ width: "75px", fontSize: "20px" }}
                            className={"bg-light border-primary text-primary center"}
                          >
                            <i className="ni ni-shield-check"></i>
                          </Button>
                        </div>
                      </div>
                    </Col>

                    <Col className="mt-2 mt-sm-2 mt-md-2 mt-lg-0 mt-xl-0 col-12 col-md-12 col-sm-12 col-xl-6 col-lg-6">
                      <div className="form-control-wrap">
                        <span className="nk-kycfm-label-text">
                          <b>Mobile Phone Number</b>
                          <span className="text-danger">&nbsp;*</span>
                        </span>
                        <div className="input-group input-group-lg">
                          <input
                            type="text"
                            name="phone"
                            placeholder={"Example: 6471111111"}
                            className="form-control"
                            {...register("phone", {
                              required: "This field is required",
                              pattern: {
                                value: /^(?:\+?1[-.\s]?)?\(?[2-9][0-8][0-9]\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                                message: "Please input valid phone number",
                              },
                            })}
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          />

                          <Button
                            disabled={
                              !/^(?:\+?1[-.\s]?)?\(?[2-9][0-8][0-9]\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(watch("phone")) ||
                                errors.phone
                                ? true
                                : false
                            }
                            type="button"
                            style={{ width: "75px" }}
                            onClick={(e) => sendSms(e)}
                            className={"border-primary text-primary "}
                          >
                            {loading ? <Spinner size="sm" color="light" /> : "Verify"}
                          </Button>
                          {errors.phone && <p className="invalid">{errors.phone.message}</p>}
                        </div>
                      </div>{" "}
                    </Col>
                  </Row>
                  {/* <ContactModal
                    toggle={closeModal}
                    isOpen={modalOpen}
                  ></ContactModal> */}
                  <Modal backdrop={success && "static"} isOpen={modalOpen} toggle={closeModal}>
                    <ModalHeader
                      toggle={closeModal}
                      close={
                        <button disabled={success} className="close" onClick={closeModal}>
                          <Icon name="cross" />
                        </button>
                      }
                    >
                      Verify Phone Number
                    </ModalHeader>
                    <ModalBody>
                      <p className="text-center">
                        Enter the code sent by SMS/Text to the phone number you provided.
                      </p>
                      <div className="center text-center">
                        <Input
                          errors={errors}
                          required
                          requiredMessage="This field is required"
                          register={register}
                          type="text"
                          label="Verification Code"
                          id="code"
                          name="code"
                          className="form-control-lg form-control"
                        ></Input>
                      </div>
                      {/* <div className="mt-3 nk-kycfm-note">
                        <em className=" icon ni ni-info-fill"></em>
                        <p>
                          We use your current location to verify that it matches your address on at least one of your ID
                          documents.
                        </p>
                      </div> */}
                      <div className="mt-2 mb-3 center">
                        <Button
                          disabled={!(watch("code") && watch("code").length > 5) || smsLoading}
                          color="primary"
                          size=""
                          className=""
                          outline=""
                          type="submit"
                          onClick={handleSubmit(onFormSubmit)}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : "Verify"}
                        </Button>
                      </div>
                    </ModalBody>
                  </Modal>
                  <div className="mt-3 nk-kycfm-note">
                    <em className=" icon ni ni-info-fill"></em>
                    <p>
                      This information will be used to verify your contact details. You will not be subscribed to any
                      mailing or promotional lists.
                    </p>
                  </div>

                  <div className="bg-primary" style={{ position: "fixed", bottom: "0px" }}>
                    <div className="bg-white col-12" style={{ position: "fixed", bottom: "0px", right: "0px" }}>
                      <div style={{ margin: "10px 5px 10px 0px", float: "right" }} className="p-1">
                        {" "}
                        {/* <Button color="primary" size="" outline="">
                          Continue
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="actions clearfix">
                    <ul>
                      <li>
                        <button type="submit" style={{float:'right'}} className="btn btn-primary">Next</button>
                      </li>
                    </ul>
                  </div> */}
            </form>
          </Block>
        </div>
      </Content>

      <ToastContainer />
    </React.Fragment>
  );
};

export default Contact;
