import Content from "../../../../../layout/content/Content";
import React, { useEffect, useRef, useState } from "react";
// import ShareAddExisting from "./shareAddExisting/ShareAddExisting";

import { Block, BlockAthenty, Icon } from "../../../../../components/Component";
import { Card } from "reactstrap";
import VerifyWizard from "./verifyWizard/VerifyWizard";

const VerifyAdd = () => {
  const submitRef = useRef();
  const [selectedTab, setSelectedTab] = useState("new");

  const asignRef = () => {
    return submitRef.current.click();
  };

  return (
    <React.Fragment>
      <Content page="component">
        <BlockAthenty
          head={"New Smart IDV Request"}
          nav="Solutions / Smart IDV"
          title={"New Smart IDV Request"}
          back={"/customer/solutions/verify"}
          description="Send new Verify request"
          buttons={[
            {
              // type:"submit",
              text: "Send",
              icon: "send",
              link: "",
              onclick: asignRef,
              tooltip: "Send verification request",
              id: "submit",
              form: "verify-form",
              color: "primary",
            },
          ]}
        />
      </Content>
      <Block className="nk-content-wrap">
        <Card className="card-bordered ">
          <div className="card-inner card-inner-lg">
            <VerifyWizard />
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default VerifyAdd;
