import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Block, Icon, PreviewCard, Textarea } from "../../components/Component";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Alert, Button, Form, Input, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../utils/Api";
import { toast } from "react-toastify";

const ClientApproval = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState();
  const navigate = useNavigate();

  const [data, setData] = useState();
  const params = useParams();
  const share_uuid = params.smartVerifyUuid;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getShareDetail();
  }, []);

  useEffect(() => { }, [data]);

  const getShareDetail = async () => {
    console.log(share_uuid);
    await apiRequest(`/client/solution/smart-verify/${share_uuid}/share`, "get").then((resp) => {
      if (resp.code === 200) {
        console.log(resp);
        setData(resp.data);
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const sharePermission = async (permission) => {
    await apiRequest(`/client/solution/smart-verify/${share_uuid}/share`, "post", { permission }).then(async (resp) => {
      if (resp.code === 200) {
        console.log(resp);
        setData(resp.data);
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <Head title="Share PDF" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <div className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </div>
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <div className="form-group">
            <div className="form-control-wrap">
              <div className="row">
                <div className="col">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                </div>
                <div className="col">
                  <span id="name" name="name" type="text">
                    {data && data.name}
                  </span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="name" className="form-label">
                    Email Address
                  </label>
                </div>
                <div className="col">
                  <span id="email" name="email" type="text">
                    {data && data.email}
                  </span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="name" className="form-label">
                    Mobile Number
                  </label>
                </div>
                <div className="col">
                  <span id="mobile_number" name="mobile_number" type="text">
                    {data && data.mobile}
                  </span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="name" className="form-label">
                    Company or Business Name
                  </label>
                </div>
                <div className="col">
                  <span id="business_name" name="business_name" type="text">
                    {data && data.company}
                  </span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="name" className="form-label">
                    Reason
                  </label>
                </div>
                <div className="col">
                  <span id="reason" name="business_name" type="text">
                    {data && data.reason}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {data && !data.permission ? (
            <div className="center mt-2">
              <Button className="btn btn-success me-2 m-1 closer" onClick={() => sharePermission(1)}>
                Approve
              </Button>
              <Button className="btn btn-danger me-2 m-1 closer" onClick={() => sharePermission(0)}>
                Reject
              </Button>
            </div>
          ) : (
            <span className="text-danger center">This request has already been reviewed.</span>
          )}
          {/* {data && data.permission && (
            <span className="text-danger center">This request has already been reviewed.</span>
          )}

          {data && data.permission === null && (
            <div className="center mt-2">
              <Button className="btn btn-success me-2 m-1 closer" onClick={() => sharePermission(1)}>
                Approve
              </Button>
              <Button className="btn btn-danger me-2 m-1 closer" onClick={() => sharePermission(0)}>
                Reject
              </Button>
            </div>
          )} */}
        </PreviewCard>
      </Block>
    </>
  );
};

export default ClientApproval;
