import { useLocation, useParams } from "react-router";
import Head from "../../layout/head/Head";
import { apiRequest } from "../../utils/Api";
import { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import { Block, Icon, PreviewCard } from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import HeaderCCR from "../../layout/header/HeaderCCR";
import AuthFooter from "../../pages/auth/AuthFooter";
import "./ThirdPartyPdf.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ThirdPartyPdf = (props) => {
  // const { state } = useLocation();
  // const data = state;
  console.log(props.data);
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);

    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === "I" || event.key === "i")) {
        event.preventDefault();
      }
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === "C" || event.key === "c")) {
        event.preventDefault();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        event.preventDefault();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "c") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const prevPage = () => {
    setPageNumber(pageNumber <= 1 ? 1 : pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? pageNumber : pageNumber + 1);
  };

  return (
    <>
      <Head title="View Report" />

      <Block className="pdfDisplay">
        <HeaderCCR title='Verification' />
        <div className="d-flex center">
          {pageNumber !== 1 && (
            <i className="px-2 font-weight-bold" onClick={prevPage} role="button">
              <Icon name="arrow-left" className="px-1" />
              Previous Page
            </i>
          )}
          {pageNumber !== 6 && (
            <i className="px-2" onClick={nextPage} role="button">
              Next Page
              <Icon name="arrow-right" className="px-1" />
            </i>
          )}
        </div>
        <Document
          className="center disableTextSelection"
          file={`data:application/pdf;base64,${props.data}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <AuthFooter />
      </Block>

      {/* {!data && (
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <div className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </div>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <div className="center text-danger">
              <span>The link is invalid</span>
            </div>
          </PreviewCard>
        </Block>
      )} */}
    </>
  );
};

export default ThirdPartyPdf;
