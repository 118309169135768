import React from "react";
import Content from "../../../../../../layout/content/Content";

const ShareProfile = (props) => {
  return (
    <React.Fragment>
      {props.data && (
        <Content>
          <div className="nk-block-head">
            <h5 className="title">Matter Details</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      ID
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "75%",
                      }}
                      id="id"
                      className="profile-ud-value"
                    >
                      {(props.data.matter_id && props.data.matter_id) || "Not collected"}
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Reason
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "75%",
                      }}
                      id="purpose"
                      className="profile-ud-value"
                    >
                      {props.data && props.data.matter_reason ? props.data.matter_reason : ""}
                    </span>
                  </div>
                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Requested Documents
                    </span>


                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "75%",
                      }}
                      id="purpose"
                      className="profile-ud-value"
                    >
                      {console.log}
                      {props.data && props.data.req_params ?
                        Object.values(props.data.req_params.documents).map(doc => doc.text).join(', ')
                        :
                        ""
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block-head">
            <h5 className="title">Verification</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                      }}
                      className="profile-ud-label"
                    >
                      Requested by
                    </span>

                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        margin: "0px 20px 0px 0px",
                        width: "75%",
                      }}
                      id="requested-by"
                      className="profile-ud-value"
                    >
                      {(props.data.requestor && props.data.requestor.name) || ""} (
                      {(props.data.requestor && props.data.requestor.title) || ""})
                    </span>
                  </div>

                  <hr style={{ margin: "0" }} />
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "15%",
                        textAlign: "left",
                      }}
                    >
                      Type
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Sent
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Started
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Returned
                    </span>
                    {/* <span
                      style={{
                        fontSize: "14px",
                        width: "20%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Result
                    </span> */}
                  </div>
                  <div className="profile-ud wider">
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "15%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="tier"
                    >
                      {(props.data && props.data.tier && props.data.tier) || "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="sent-date"
                    >
                      {(props.data && props.data.created_at && props.data.created_at.substring(0, 10)) ||
                        "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="started-date"
                    >
                      {/* {props.data && props.data.welcome.created_at && props.data.welcome.created_at.substring(0, 10) || ''} */}
                      {(props.data &&
                        props.data.start_date &&
                        props.data.start_date.substring(0, 10)) ||
                        "---"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="returned-date"
                    >
                      {(props.data &&
                        props.data.submit_date &&
                        props.data.submit_date.substring(0, 10)) ||
                        "---"}
                    </span>
                    {/* <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "20%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="approved-date"
                    >
                      {(props.data &&
                        props.data.result &&
                        props.data.result.charAt(0).toUpperCase() + props.data.result.slice(1)) ||
                        "---"}
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block-head">
            <h5 className="title">Client</h5>
          </div>
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="profile-ud wider">
                    <span
                      style={{
                        fontSize: "14px",
                        margin: "0px 0px 0px 20px",
                        width: "25%",
                        textAlign: "left",
                      }}
                    >
                      ID
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "25%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "25%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Email
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        width: "25%",
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                      }}
                    >
                      Mobile
                    </span>
                  </div>
                  <div className="profile-ud wider">
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "25%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="id"
                    >
                      {(props.data && props.data.external_id) || "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "25%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="name"
                    >
                      {(props.data && props.data.name) || "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "25%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="email"
                    >
                      {(props.data && props.data.to_email) || "Not collected"}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        margin: "0px 0px 0px 20px",
                        fontSize: "14px",
                        width: "25%",
                        color: "#526484",
                        fontWeight: "500",
                      }}
                      id="mobile"
                    >
                      {(props.data && props.data.to_phone) || "Not collected"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};

export default ShareProfile;
