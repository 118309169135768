import React, { useEffect, useState } from "react";
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Block, Icon, Input, InputCheckbox, Loading, UserAvatar } from "../../../../../../components/Component";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../../../../../utils/Api";
import "../Kyc.css";
import RSelect2 from "../../../../../../components/select/ReactSelect2";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const packageList = [
  {
    id: 1,
    name: "free",
    title: "Free (Identification)",
    sub: "Self Verify & Authenticate",
    price: 0,
  },
  {
    id: 2,
    name: "Basic",
    title: "Basic (Verification)",
    sub: "A.I. Verification",
    price: 7,
    disabled: true,
  },
  {
    id: 3,
    name: "Enhanced",
    title: "Enhanced (Authentication)",
    sub: "A.I. Verification & Authentication",
    price: 15,
    disabled: true,
  },
];

const dataArray = Array.from({ length: 12 }, (_, index) => ({
  value: generateRandomString(30),
  label: generateRandomName(),
}));

// Function to generate a random string of a specified length
function generateRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

// Function to generate a random name
function generateRandomName() {
  const firstNames = ["John", "Jane", "Alice", "Bob", "Charlie"];
  const lastNames = ["Doe", "Smith", "Johnson", "Williams", "Brown"];

  const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];

  return `${randomFirstName} ${randomLastName}`;
}

const KycAddExisting = (props) => {
  const [clientData, setClientData] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState("");
  const [tierData, setTierData] = useState("free");
  const [activeTier, setActiveTier] = useState(packageList[0]);
  const [loading, setLoading] = useState();
  const [options, setOptions] = useState();
  const toggleModal = () => setModal(!modal);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm();

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    setLoading(true);
    await apiRequest("/customer/a/solution/smart-kyc/existing-clients", "GET").then((resp) => {
      setClientData(resp.data);
      console.log(resp.data);
      let options = [];
      for (const client of resp.data) {
        options.push({
          value: client.uuid,
          label: `${client.name_first} ${client.name_last} - ${client.external_id}`,
        });
      }
      setOptions(options);
      setLoading(false);
    });
  };

  const checkboxChange = (e) => {
    setTierData(e.name);
    var result = packageList.filter((obj) => {
      return obj.name === e.name;
    });
    setActiveTier(result[0]);
  };

  const onselect = (name, value) => {
    setValue(name, value);
    clearErrors(name);
    setSelectedClient(clientData.find((x) => x.uuid === value));
  };

  const openModal = (formData) => {
    // console.log(formData);
    setFormData(formData);
    toggleModal();
  };

  const onFormSubmit = async () => {
    setLoading(true);
    const formData = {
      client_name_first: selectedClient.name_first,
      client_name_last: selectedClient.name_last,
      client_id: getValues("client_id"),
      client_email: selectedClient.email,
      client_phone: selectedClient.phone,
      matter: selectedClient.external_id,
      request_reason: getValues("request_reason"),
    };
    console.log(formData);
    await apiRequest("/customer/a/solution/smart-kyc/", "POST", formData).then((resp) => {
      if (resp.code === 200) {
        navigate("/customer/solutions/kyc", { state: "clientAdded" });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };
  //   const onInputChange = (e, location) => {
  //     if (location === "location") {
  //       let formDataCopy = { ...formData };
  //       console.log(formDataCopy);
  //       formDataCopy.location[e.target.name] = e.target.value;
  //       setFormData(formDataCopy);
  //       return;
  //     }
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   };

  return (
    <React.Fragment>
      <Block>
        {/* <form id='kyc-form' className="content clearfix" onSubmit={handleSubmit(submitForm)}> */}
        {loading && <Loading />}
        {!loading && (
          <form id="kyc-form" className=" is-alter content clearfix" onSubmit={handleSubmit(openModal)}>
            <Row>
              <Col xl="5">
                <div className="card-inner-group">
                  <div className="sp-plan-head">
                    <h5 className="mt-2 mb-4 text-center title">Request Details</h5>
                  </div>
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <RSelect2
                        onselect={onselect}
                        setValue={setValue}
                        label="Client"
                        register={register}
                        requiredMessage="This field is required"
                        id="matter_id"
                        name="matter_id"
                        errors={errors}
                        options={options}
                        placeholder={"Select client"}
                        className="form-control-lg p-0 border-0 form-control"
                      />
                    </div>
                  </div>
                  {errors.matter && errors.matter.message}
                  {/* <div className="form-group">
                    <div className="form-control-wrap">
                      <Input
                        errors={errors}
                        register={register}
                        type="text"
                        label="Client ID"
                        id="client_id"
                        name="client_id"
                        className="form-control-l form-control"
                      />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="request_reason">
                        Matter Description<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <textarea
                        type="text"
                        bssize="lg"
                        id="request_reason"
                        {...register("request_reason", { required: true })}
                        className="form-control-l form-control"
                      />
                      {errors.request_reason && <p className="invalid">This field is required</p>}
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl="7">
                <div className="card-inner-group">
                  <div className="sp-plan-head">
                    <h5 className="mt-2 mb-4 text-center title">Select Verification Tier</h5>
                  </div>
                </div>
                <span className="center text-center" style={{ fontSize: "15px", margin: "-5px 0 15px 0" }}>
                  <span
                    type="button"
                    style={{ width: "120px", height: "20px" }}
                    className="center btn btn-sm btn-primary text-light"
                  >
                    View Features
                  </span>
                </span>
                <div id="radio_selector_type" className="form-group">
                  {packageList.map((p) => {
                    return (
                      <Card key={p.id} className={`mb-2 p-2 card-bordered ${p.disabled && "disabled"}`}>
                        <div className="project-title">
                          <div onChange={(e) => checkboxChange(e.target)}>
                            <InputCheckbox checked={tierData === p.name} id={p.name}></InputCheckbox>
                          </div>
                          <UserAvatar text={"$" + p.price} className="sq" />
                          <div className="project-info">
                            <h6 className="title">{p.title}</h6>
                            <div className="project-info">
                              <span className="">{p.sub}</span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>

                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="sp-plan-head">
                      <h5 className="mt-2 text-center title">Request Details</h5>
                    </div>
                  </div>
                  <hr style={{ margin: "0 15px" }}></hr>
                  <div className="pricing-body m-2">
                    <ul className="pricing-features">
                      <li>
                        <span className="w-50">
                          <div>{activeTier && activeTier.name}</div>
                          <div className="form-note">{activeTier && activeTier.sub}</div>
                        </span>
                        <span className="ms-auto lead-text">${activeTier && activeTier.price}</span>
                      </li>
                      <hr></hr>
                      <li>
                        <span className="w-50">
                          <div>Subtotal</div>
                        </span>
                        <span className="ms-auto lead-text">${activeTier && activeTier.price}</span>
                      </li>
                      <li>
                        <span className="w-50">
                          <div>Tax</div>
                        </span>
                        <span className="ms-auto lead-text">${activeTier && (activeTier.price * 0.1).toFixed(2)}</span>
                      </li>
                      <li>
                        <span className="w-50">
                          <div>Processing Fees</div>
                        </span>
                        <span className="ms-auto lead-text">${activeTier && (activeTier.price * 0.01).toFixed(2)}</span>
                      </li>
                      <hr></hr>
                      <hr style={{ marginTop: "-12px" }}></hr>
                      <li>
                        <span className="w-50">
                          <div className="lead-text">Total</div>
                        </span>
                        <span className="ms-auto lead-text">
                          $
                          {activeTier &&
                            (activeTier.price + activeTier.price * 0.01 + activeTier.price * 0.1).toFixed(2)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
            </Row>
            {/* <button ref={props.submitRef} type="submit" id='add-new-verify' style={{ display: 'none' }} /> */}

            <button ref={props.submitRef} type="submit" id="add-new-kyc" style={{ display: "none" }} />
          </form>
        )}
      </Block>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader
          toggle={toggleModal}
          close={
            <button className="close" onClick={toggleModal}>
              <Icon name="cross" />
            </button>
          }
        >
          Confirm Purchase
        </ModalHeader>
        <ModalBody>
          <Card className="card-bordered">
            <div className="card-inner">
              <div className="sp-plan-head">
                <h5 className="mt-2 text-center title">Request Details</h5>
              </div>
            </div>
            <hr style={{ margin: "0 15px" }}></hr>
            <div className="pricing-body m-2">
              <ul className="pricing-features">
                <li>
                  <span className="w-50">
                    <div>{activeTier && activeTier.name}</div>
                    <div className="form-note">{activeTier && activeTier.sub}</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && activeTier.price}</span>
                </li>
                <hr></hr>
                <li>
                  <span className="w-50">
                    <div>Subtotal</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && activeTier.price}</span>
                </li>
                <li>
                  <span className="w-50">
                    <div>Tax</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && (activeTier.price * 0.1).toFixed(2)}</span>
                </li>
                <li>
                  <span className="w-50">
                    <div>Processing Fees</div>
                  </span>
                  <span className="ms-auto lead-text">${activeTier && (activeTier.price * 0.01).toFixed(2)}</span>
                </li>
                <hr></hr>
                <hr style={{ marginTop: "-12px" }}></hr>
                <li>
                  <span className="w-50">
                    <div className="lead-text">Total</div>
                  </span>
                  <span className="ms-auto lead-text">
                    ${activeTier && (activeTier.price + activeTier.price * 0.01 + activeTier.price * 0.1).toFixed(2)}
                  </span>
                </li>
              </ul>
            </div>
          </Card>

          <p className="mt-2">
            <b>NOTE:</b> Your primary payment method will be charged the subtotal amount on purchase
          </p>
          <div className="center mt-2">
            <button onClick={toggleModal} className="center btn border me-2 m-1 closer" style={{ width: "90px" }}>
              Cancel
            </button>
            <button
              form="kyc-form"
              className="btn btn-primary "
              onClick={() => {
                onFormSubmit();
                toggleModal();
              }}
            >
              Purchase
            </button>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};
export default KycAddExisting;
