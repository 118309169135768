import { Steps, Step } from "react-step-builder";
import { PreviewCard } from "../../../../../../components/Component";
import ShareWizardType from "./shareWizardType/ShareWizardType";
import { Col, Container, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import ShareWizardUser from "./shareWizardUser/ShareWizardUser";
import ShareWizardSelection from "./shareWizardSelection/ShareWizardUser";
import ShareWizardStart from "./shareWizardStart/ShareWizardStart";
import ShareWizardFinal from "./shareWizardFinal/ShareWizardFinal";
import { apiRequest } from "../../../../../../utils/Api";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";


const Header = (props) => {
    return (
        <div className="steps clearfix">
            <ul>
                <li className={props.current >= 1 ? "done" : ""}>
                    <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                        <span className="number">01</span>
                    </a>
                </li>
                <li className={props.current >= 2 ? "done" : ""}>
                    <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                        <span className="number">02</span>
                    </a>
                </li>
                <li className={props.current >= 3 ? "done" : ""}>
                    <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                        <span className="current-info audible">current step: </span>
                        <span className="number">03</span>
                    </a>
                </li>
                <li className={props.current >= 4 ? "done" : ""}>
                    <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                        <span className="current-info audible">current step: </span>
                        <span className="number">04</span>
                    </a>
                </li>

            </ul>
        </div>
    );
};

const ShareWizard = () => {

    const navigate = useNavigate()

    const config = {
        before: Header,
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        getValues,
        watch,
        setValue,
        unregister
    } = useForm();

    const submit = async (event) => {
        console.log('submit')
        console.log(getValues())
    }

    const formSubmit = async () => {
        console.log(getValues())
        console.log('test')
        await apiRequest("/customer/a/solution/smart-share", "POST", getValues()).then((resp) => {
            // setLoading(true)
            console.log('test')
            if (resp.code === 200) {
                navigate("/customer/solutions/share", { state: "clientAdded" });
                console.log('done')
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                        <Steps config={config}>
                            <Step errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} component={ShareWizardStart} />
                            <Step errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} component={ShareWizardUser} />
                            <Step errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} component={ShareWizardType} />
                            <Step errors={errors} clearErrors={clearErrors} setError={setError} unregister={unregister} submit={submit} handleSubmit={handleSubmit} watch={watch} register={register} setValue={setValue} getValues={getValues} component={ShareWizardFinal} formSubmit={formSubmit} />
                        </Steps>
                    </div>
                </Col>


            </Row>
        </Container>
    )
}

export default ShareWizard