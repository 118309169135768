import React, { useState } from "react";
import Content from "../../../../../../layout/content/Content";
import { Icon, Button, TooltipComponent } from "../../../../../../components/Component";
import GalleryImage from "../../../../../../components/partials/gallery/GalleryImage";
import { Badge, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import countryList from "react-select-country-list";
import { capitalizeWords } from "../../../../../../utils/Utils";

const ShareDocuments = ({ data }) => {
  const [documentsData] = useState(data.documents);
  console.log(documentsData)
  const [isOpen1, setIsOpen1] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [tooltipOpen1, setOpen1] = useState(false);
  const [tooltipOpen2, setOpen2] = useState(false);
  const ordinalMap = {
    1: 'First',
    2: 'Second',
    3: 'Third',
    4: 'Fourth',
    5: 'Fifth',
    6: 'Sixth',
    7: 'Seventh',
    8: 'Eighth',
    9: 'Ninth',
    10: 'Tenth'
    // Add more mappings as needed
  };
  const toggle1 = () => {
    console.log('1')
    setIsOpen1(!isOpen1);
    setModal1(!modal1);
  };

  const toggle2 = () => {
    setIsOpen2(!isOpen2);
    setModal2(!modal2);
    console.log('2')
  };

  const toggleTooltip1 = () => {
    setOpen1(!tooltipOpen1);
  };

  const toggleTooltip2 = () => {
    setOpen2(!tooltipOpen2);
  };

  const getResult = (search, results) => {
    for (let i = 0; i < results.length; i++) {
      if (search.Alias === Object.keys(results[i])[0]) {
        return results[i][search.Alias];
      }
    }
    return "No result";
  };



  return (
    <React.Fragment>
      <Content>
        {!documentsData && (
          <div className="nk-block-head">
            <div className="card card-bordered card-stretch">
              <h5 className="title" style={{ textAlign: "center", margin: "10px" }}>
                No Documents Collected
              </h5>
            </div>
          </div>
        )}
        {documentsData && documentsData.map((document, index) => {
          return (
            <React.Fragment key={index}>
              <div className="nk-block-head">
                {/* <h5 className="title">
                  {ordinalMap[index + 1]}
                  <span style={{ textAlign: "right", position: "absolute", right: "0px" }}>


                  </span>

                </h5> */}
              </div>
              <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">

                        <hr style={{ margin: "0" }} />
                        <div className="profile-ud wider">
                          <h5
                            style={{
                              margin: "0px 0px 0px 20px",
                              width: "25%",
                            }}
                            className="profile-ud-label"
                          >
                            {console.log(document)}
                            {data.req_params.documents[document.type].text}
                          </h5>
                          <span
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              margin: "0px 20px 0px 0px",
                              width: "75%",
                            }}
                            id="number"
                            className="profile-ud-value"
                          >

                          </span>
                          {/* {document.type === "credit" && (
                            <>
                              {" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 20px",
                                  width: "25%",
                                }}
                                className="profile-ud-label"
                              >
                                Name On Bill
                              </span>
                              <span
                                style={{
                                  textAlign: "left",
                                  fontSize: "14px",
                                  margin: "0px 20px 0px 0px",
                                  width: "75%",
                                }}
                                id="number"
                                className="profile-ud-value"
                              >
                                {
                                  document.type === "credit" &&
                                    document.file3_ocr_results.find((obj) => obj.hasOwnProperty("IS_FRONT"))
                                    ? document.file3_ocr_results.find((obj) => obj.hasOwnProperty("IS_FRONT"))
                                      .IS_FRONT
                                    : 'Not Found"'}
                              </span>
                            </>
                          )} */}
                        </div>

                        <hr style={{ margin: "0" }} />
                        <div className="profile-ud wider">

                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                            }}
                          >
                            {document.file1_s3url && (
                              <>
                                {document.type === 'credit' && <div>Credit card front</div>}
                                {/* <GalleryImage img={document.file1_s3url} height="250px" /> */}
                                {document.file1_s3url === 'privacy' ?
                                  <div class="border d-flex align-items-center justify-content-center" style={{ height: "200px", backgroundColor: "black" }}>

                                    <div class="card-body">
                                      <p class="card-text">For privacy reasons, this document cannot be shown</p>
                                    </div>
                                  </div>
                                  :
                                  <GalleryImage img={document.file1_s3url} height="250px" />
                                }
                              </>
                            )}
                          </span>
                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              margin: "0px 0px 0px 20px",
                            }}
                          >
                            {document.type === 'credit' && <div>Credit card back</div>}
                            {/* {document.file2_s3url && (
                              < GalleryImage img={document.file2_s3url} height="250px" />
                            )} */}
                            {document.file2_s3url === 'privacy' && document.type === 'credit' ?
                              <div class="border d-flex align-items-center justify-content-center" style={{ height: "200px", backgroundColor: "black" }}>

                                <div class="card-body">
                                  <p class="card-text">For privacy reasons, this document cannot be shown</p>
                                </div>
                              </div>
                              :
                              <GalleryImage img={document.file2_s3url} height="250px" />
                            }
                          </span>
                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              margin: "0px 20px 0px 20px",
                            }}
                          >
                            {/* {document.type === 'credit' && <div>Bill</div>} */}
                            {/* {document.file3_s3url && (
                              <GalleryImage img={document.file3_s3url} height="250px" />
                            )} */}
                            {/* {document.file3_s3url === 'privacy' && document.type === 'credit' &&
                              <div class="border d-flex align-items-center justify-content-center" style={{ height: "200px", backgroundColor: "black" }}>

                                <div class="card-body">
                                  <p class="card-text">For privacy reasons, this document cannot be shown</p>
                                </div>
                              </div>
                            } */}

                            {document.type === 'credit' && <GalleryImage img={document.file3_s3url} height="250px" />}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </Content>
    </React.Fragment>
  );
};

export default ShareDocuments;
